import {
    consoleApi,
    DEAL_TAG,
    DEAL_ORIGINS_TAG,
    DEAL_STATUS_TAG,
    SEAT_ALL_DEALS_TAG,
    BUYER_STATUS_TAG,
    DEAL_AD_SOURCE_TAG,
    SEAT_ALL_DEALS_PICK_LIST_TAG,
    CHANGELOG_TAG,
} from "./console";
import { toQueryString } from "./utils";
import { LabelValue } from "./labels";
import {
    AdSourcePayload,
    ChangeLogEntityType,
    Dmp,
    InventoryDistributionGroup,
    PacingProgress,
    SynthesizedAdSourceStatus,
    Targeting,
    TargetingCreatePayload,
    TargetingUpdatePayload,
    TimeZone,
} from ".";
import { DealAdSourceTransparencyExtendedUserId, FreqCapping } from "@app/features/deals/DealForm/types";
import { AdSource } from "./";
import { EntityTypes } from "@app/features/adStats";
import { AdSourceTypeIds, PricingModels } from "@app/features/seatAdSources/constants";

interface Entity {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
}

export type DealOrigin = Entity & {
    entityType: "DealOrigin";
};

export type DealStatus = Entity & {
    sort: number;
    entityType: "DealStatus";
};

export type BuyerStatus = Entity & {
    sort: number;
    entityType: "BuyerStatus";
};

export type DealClassAnnotation =
    | "com.tremorvideo.ssp.platform.model.MarketPlaceInfoAuctionPackageDeal"
    | "com.tremorvideo.ssp.platform.model.MarketPlaceInfoBuyerDeal"
    | "com.tremorvideo.ssp.platform.model.MarketPlaceInfoDemandDeal";

interface DealSeat {
    id: number;
    name: string;
    code: string;
    entityType: "Seat";
    active: boolean;
    status: Entity;
    reactivationDate: null;
    dealIncluded: null;
    revision: number;
    network: {
        id: number;
        seat: DealSeat;
        name: string;
        allowedCurrencies: unknown[];
        entityType: "Network";
    };
    externalName: string;
    externalParentName: string;
    externalId: null;
    externalParentId: null;
    externalStatus: null;
    dealTransactionType: Entity & { value: string };
    dealPricingType: Entity & { value: string };
    startTime: string;
    endTime: string;
    timeZone: Entity & { code: string };
    bookingVolume: number;
    rate: number;
    currencyType: {
        creationDate: string;
        updateDate: string;
        id: number;
        code: string;
    };
}

interface DealBase {
    creationDate: string;
    updateDate: string;
    bookingVolume: null | number;
    id: number;
    seat: DealSeat;
    marketplace: null;
    dealOrigin: Entity;
    dealIncluded: null;
    dealTransactionType: Entity & { value: string };
    dealPricingType: Entity & { value: string };
    dealType: {
        name: string;
        id: number;
    };
    dealPrices: null; // deprecated
    description: null | string;
    name: string;
    code: string;
    active: boolean;
    status: Entity;
    reactivationDate: null;
    revision: number;
    rate: number;
    network: {
        id: number;
        seat: DealSeat;
        name: string;
        allowedCurrencies: unknown[];
        entityType: "Network";
    };
    dealFreqCappings: Array<FreqCapping>;
    enforcement: { id: number; name: string; creationDate: string; updateDate: string };
    freqCappingType: { id: number; name: string; creationDate: string; updateDate: string };

    priceModel: Entity;
    resetFrequency: null;
    tierResetMode: null;
    tierOverflowAllowed: null;
    tierResetMonthDay: null;
    sharedSeats: {
        code: string;
        entityType: EntityTypes;
        id: number;
        name: string;
    }[];
    sharedMarketplaces: {
        authorizedDmps: Array<Dmp>;
        creationDate: string;
        description: string;
        id: number;
        name: string;
        updateDate: string;
    }[];
    currentlyUsedInAdSources: {
        [key: string]: string;
    };
    timeZone: Entity & { code: string };
    synthesizedStatus: null;
    notes: null;
    externalName: null | string;
    externalParentName: null | string;
    externalId: null;
    externalParentId: null;
    externalStatus: null | string;
    startTime: string;
    endTime: string | null;
    currencyType: {
        creationDate: string;
        updateDate: string;
        id: number;
        code: string;
    };
    pacingPeriod: {
        id: number;
    } | null;
    pacingType: {
        id: number;
    } | null;
    customCurveImps?: number;
    customCurveStartDate?: string;
    redistributePacing?: boolean;
}

export interface BuyerDeal extends DealBase {
    demandFee: null;
    publisherName?: string;
    publisherEmail?: string;
    buyerSeats: Entity & { code: string }[];
    buyerDealSeller: null;
    entityType: "BuyerDeal";
}

export interface DemandDeal extends DealBase {
    publisherName?: string;
    publisherEmail?: string;
    entityType: "DemandDeal";
}

export interface LegacyDeal {
    creationDate: string;
    updateDate: string;
    id: number;
    seat: DealSeat;
    marketplace: null;
    dealOrigin: Entity;
    name: string;
    code: string;
    description: null;
    active: boolean;
    status: Entity;
    reactivationDate: null;
    dealIncluded: null;
    entityType: "Deal";
}

export interface DealAdSource {
    name: string;
    priority: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
    };
    bookingPrice: number;
    bookingStartDate: string;
    id: string | number;
}

export type Deal = BuyerDeal | DemandDeal | LegacyDeal;

export interface DealListItem {
    id: number;
    code: string;
    name: string;
    seat: {
        id: number;
        name: string;
        code: string;
        entityType: "Seat";
    } | null;
    buyerSeats?: {
        id: number;
        code: string;
        name?: string;
    }[];
    priceModel?: {
        id: number;
        name: string;
    };
    publisherName?: string;
    publisherEmail?: string;
    dealOrigin: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
    };
    active: boolean;
    status: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
    };
    entityType: string;
    externalStatus: null;
    sharedMarketplaces?: { id: number; name: string; code: string }[];
    sharedSeats?: { id: number; name: string; code: string }[];
    dealTransactionType: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
        value: null;
    } | null;
    dealPricingType: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
        value: null;
    } | null;
    dealType: {
        id: number;
        name: string;
    };
    currencyType: {
        id: number;
        code: string;
    };
    startTime: string;
    endTime: string;
    bookingVolume: number;
    network: {
        id: number;
        name: string;
        seat: null;
        allowedCurrencies: null;
        entityType: "Network";
    };
    creationDate: string;
    updateDate: string;
    adSources: DealListSource[];
    pacing: DealPacing[] | null;
    rate: number;
    timeZone: {
        id: number;
        code: string;
        name: string;
    };
    createdWithBuyerSeats?: boolean;
    dealPrices: null; // deprecated
}

export interface DealPacing {
    adSourceId: number;
    customCurveImps: number;
    customCurveStartDate: string;
    dailyCapValue: number;
    dailyImpsRemaining: number;
    daysElapsed: number;
    daysRemaining: number;
    deliveryPercentage: number;
    impsPerDayToFulfill: number;
    impsRemaining: number;
    monthImpCount: number;
    pacingDelta: string;
    pacingStatusPercentage: number;
    recordedTotalImpCount: number;
    reqOpportunity: number;
    todayImpCount: number;
    todaysGoal: number;
    totalImpCount: number;
    yearImpCount: number;
    yesFills: number;
    yesImpressions: number;
    yesTries: number;
    pacingProgress: PacingProgress;
}

export interface DealListSource {
    name: string;
    bookingEndDate: string;
    bookingPrice: number;
    bookingStartDate: string;
    id: number;
    priority: {
        creationDate: string;
        id: number;
        name: string;
        updateDate: string;
    };
    pacingType: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
    };
    // TODO - currently, this isn't being returned by the API
    synthesizedStatus?: SynthesizedAdSourceStatus;
}

export interface DealList {
    deal: DealListItem;
    dealListSources: DealListSource[];
    dealPacing: DealPacing[] | null;
}

export interface ServerDealResponse {
    deals: DealList[];
    maxResults: number;
    page: number;
    totalResults: number;
}

export interface UIDealListItem {
    deals: DealListItem[];
}

export interface UIDeal extends UIDealListItem {
    maxResults: number;
    page: number;
    totalResults: number;
}

export interface DealCreated {
    active: boolean;
    code: string;
    creationDate: string;
    updateDate: string;
    dealIncluded: unknown;
    dealOrigin: {
        creationDate: string;
        id: number;
        name: null | string;
        updateDate: string;
    };
    description: unknown;
    entityType: string;
    id: number;
    marketplace: unknown;
    name: string;
    reactivationDate: string | null;
    seat: {
        code: string;
        entityType: string;
        id: number;
        name: string;
    };
    status: {
        creationDate: string;
        id: number;
        name: string;
        updateDate: string;
    };
    dealPrices: null; // deprecated
    startTime: string;
    endTime: string | null;
    rate: number | null;
    currencyType: { code: string; id: number };
    dealType: { name: string; id: number };
    priceModel: {
        id: number;
    } | null;
    timeZone: TimeZone;
}

export interface DealAdSourceCreatePayload {
    //AdSource fields
    seat: {
        id: number;
    };
    name: string;
    description?: string | null;
    advertiserBlockDomains?: string[] | null;
    advertiserBlockingMode?: {
        id: number;
    } | null;
    seatAdvertiserDomainFilterListDefs: Array<{
        advertiserDomainListType: {
            id: number;
        };
        id: number;
    }> | null;
    //Transparency
    allowSiteName: boolean;
    allowDomainName: boolean;
    domainNameOverride: string | null;
    allowSitePage: boolean;
    allowRef: boolean;
    allowIp: boolean;
    allowBundleId: boolean;
    bundleIdOverride: string | number | null;
    allowStoreUrl: boolean;
    allowUserId: boolean;
    allowContent: boolean;
    contentTransparencyRule: {
        id: number;
    } | null;
    allowAllExtendedId: boolean;
    allowedExtendedIdTransparencies: DealAdSourceTransparencyExtendedUserId[] | [];
    assignee?: {
        id: number;
    } | null; //Assignee select
    nurlTimeoutOverride?: boolean; //Extend Timeout radio
    alwaysSendFloor: boolean;
    bookingPrice: number;
    targeting?: (Targeting | TargetingCreatePayload | TargetingUpdatePayload)[];
    targetingMode?: {
        name: string;
        id: number;
    };
    bookingStartDate: string;
    bookingEndDate: string | null;
    status: {
        id: number;
    };
    costModel?: {
        id: number;
    } | null;
    costValueFixed?: number | null;
    costValuePercent?: number | null;
    allowAutoscale: boolean;
    salesContact?: {
        id: number;
    } | null;
    externalContractId: string | null;
    auctionType?: {
        id: number;
    };
    priority: {
        id: number;
    };
    type: {
        id: number;
    };
    priceModel: {
        id: number;
    } | null;

    resetFrequency?: { id: number } | null;
    tierResetMode?: { id: number } | null;
    tierOverflowAllowed?: boolean | null;
    dealPrices: null; // deprecated
    /* tierResetMonthDay?: null, */

    //Deal fields
    marketplaceInfoList: {
        deal: DealCreatePayload;
        "@class": DealClassAnnotation;
    }[];

    //AdSource fields
    dailyCapVolume?: number | null; //AdSource Daily Impression cap field
    bookingVolume: number | null; //TODO what difference between deal bookingVolume?
    pacingType: {
        id: number;
    } | null;
    overrideDynamicFloor: boolean;
    pacingPeriod: {
        id: number;
    } | null;
    timeZone: {
        id: number;
    };
    adSourceFloorType?: {
        id: number;
    };
    region?: {
        id: number;
    } | null;
    currencyType: {
        id: number;
    };
    thirdPartyPixels: { id: number }[] | [];
    adSourceMinDuration: number | null;
    adSourceMaxDuration: number | null;
    labelValues?: LabelValue[] | null;
    strictMode: boolean;
    customCurveImps?: number | null;
    customCurveStartDate?: string | null;
    redistributePacing?: boolean | null;
    demandFee: number | null;
    demandAcquisitionCost: number | null;
    demandAcquisitionCostModel: { id: number } | null;
    dailyRequestOpportunityFallback: number | null;
    iabCategoryBlockMode: { id: number } | null;
    adResponsePriceOverrideType: { id: number } | null;
    blockedIabCategories: { id: number }[] | [];
    blockExceptionIabCategories: { id: number }[] | [];
    industryBlockMode: { id: number } | null;
    blockedIndustries: { id: number }[] | null;
    inventoryDistributionGroups?: InventoryDistributionGroup[];
}

export interface DealExistingAdSourceCreatePayload
    //TODO add overrideDynamicFloor: boolean; in AdSource interface and fallowing UI,
    //than it could be used in deal page for for existing ad source request
    extends Partial<Omit<AdSource, "marketplaceInfoList" | "targeting">> {
    marketplaceInfoList: [
        {
            deal: DealCreatePayload;
            "@class": DealClassAnnotation;
        }
    ];
    targeting: (Targeting | TargetingCreatePayload | TargetingUpdatePayload)[];
}

export interface DealCreatePayload {
    bookingVolume: string | number | null;
    buyerSeats?: {
        id: number;
    }[];
    name: string;
    externalName: string;
    externalParentName: string;
    code: string;
    status: {
        id: number;
    };

    sharedMarketplaces: { id: number }[] | [];
    sharedSeats: { id: number }[] | [];
    network: {
        id: number;
        name: string;
    };
    dealTransactionType: {
        id: number;
    };
    dealPricingType: {
        id: number;
    };
    dealOrigin: {
        id: number;
    };
    dealPrices: null; // deprecated
    currencyType: {
        id: number;
    };
    enforcement: {
        id: number;
    };
    freqCappingType: {
        id: number;
    };
    rate: string | number | null;
    endTime: string | null;
    startTime: string;
    priceModel: {
        id: number;
    } | null;
    publisherEmail: string | null;
    publisherName: string | null;
    seat: {
        id: number;
    };
    timeZone?: {
        id: number;
    };
}

export interface DealInventoryCreatePayload extends Omit<DealCreatePayload, "buyerSeats"> {
    externalStatus: number;
    mediumType: {
        id: number;
    };
    forecast?: null | {
        weeklyImpressions: number;
        weeklyUniques: number;
    };
    description: string;
    revision: 1;
}

interface CreateSeatDealParams {
    seatId: number;
    body: DealCreatePayload | DealInventoryCreatePayload;
}

interface CreateSeatDealAdSourceParams {
    seatId: number;
    body: DealExistingAdSourceCreatePayload | DealAdSourceCreatePayload | DealInventoryCreatePayload;
}

export interface DealUpdateEntity {
    entityType: "Deal";
    id: number;
    update: Partial<DealCreatePayload>;
}

export interface AdSourceUpdateEntity {
    entityType: "AdSource";
    id: number;
    update: Partial<AdSourcePayload>;
}

export type UpdateSeatDealAdSourceBody = Array<DealUpdateEntity | AdSourceUpdateEntity>;

export interface UpdateSeatDealAdSourceParams {
    seatId: number;
    body: UpdateSeatDealAdSourceBody;
}

export interface UpdateDealParams {
    id: number;
    body: Partial<DealCreatePayload>;
}

export interface GetSeatAllDealsParams {
    seatId: number;
    page?: number;
    maxResults?: number;
    filterBuyerStatus?: string[];
    searchKeyword?: string;
    filterDealOrigin?: string;
    status?: string | string[];
    type?: string | string[];
    transactionType?: string;
    filterStartData?: string;
    filterEndDate?: string;
    pricingType?: string | string[];
    orderBy?: string;
    isAscending?: boolean;
    filterPriorities?: number | string | string[];
    filterDealType?: number | string | string[];
    filterDsp?: string[];
}

interface DealsPicklistParams {
    seatId: number;
    filterDealType: number | string | string[];
    keyword?: string;
}

export const dealsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getDealById: builder.query<Deal, number>({
            query: (id: number) => `/deals/${id}`,
            providesTags: (_, __, id) => [{ type: DEAL_TAG, id }],
        }),
        getDealAdSourceById: builder.query<DealList, number>({
            query: (id: number) => `/deals/ctvNext/${id}`,
            providesTags: (_, err, id) => (err ? [] : [{ type: DEAL_AD_SOURCE_TAG, id }, SEAT_ALL_DEALS_TAG]),
            transformResponse: (response: DealList) => {
                const mappedResponse = {
                    ...response,
                    deal: {
                        ...response.deal,
                        adSources: response.dealListSources,
                        pacing: response.dealPacing,
                        createdWithBuyerSeats: Boolean(response.deal.buyerSeats?.length),
                    },
                };
                return mappedResponse;
            },
        }),
        getDealOrigins: builder.query<DealOrigin[], void>({
            query: () => "/deals/dealOrigins",
            providesTags: () => [DEAL_ORIGINS_TAG],
        }),
        getDealStatus: builder.query<DealStatus[], void>({
            query: () => "/deals/dealStatus",
            providesTags: () => [DEAL_STATUS_TAG],
        }),
        createSeatDealLegacy: builder.mutation<DealCreated, CreateSeatDealParams>({
            query: (arg: CreateSeatDealParams) => {
                const { seatId, body } = arg;
                return {
                    url: `seats/${seatId}/deals`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [SEAT_ALL_DEALS_TAG]),
        }),
        createSeatDeal: builder.mutation<DealCreated, CreateSeatDealParams>({
            query: (arg: CreateSeatDealParams) => {
                const { seatId, body } = arg;
                return {
                    url: `seats/${seatId}/deals`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [SEAT_ALL_DEALS_TAG]),
        }),
        createSeatDealAdSource: builder.mutation<DealCreated, CreateSeatDealAdSourceParams>({
            query: (arg: CreateSeatDealAdSourceParams) => {
                const { seatId, body } = arg;
                return {
                    url: `seats/${seatId}/ctvNext/dealAdSource`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [SEAT_ALL_DEALS_TAG]),
        }),
        updateDeal: builder.mutation<Deal, UpdateDealParams>({
            query: ({ id, body }: UpdateDealParams) => {
                return {
                    url: `/deals/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (_, err, { id }) =>
                err
                    ? []
                    : [
                          { type: DEAL_TAG, id },
                          SEAT_ALL_DEALS_TAG,
                          { type: CHANGELOG_TAG, id: `${ChangeLogEntityType.Deal}-${id}` },
                      ],
        }),
        deleteDeal: builder.mutation<unknown, number>({
            query: (id: number) => ({
                url: `/deals/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (err ? [] : [SEAT_ALL_DEALS_TAG]),
        }),
        getDealsPicklist: builder.query<DealListItem[], DealsPicklistParams>({
            query: ({ seatId, ...rest }: DealsPicklistParams) => {
                const params: Omit<DealsPicklistParams, "seatId"> = {
                    keyword: "",
                    ...rest,
                };
                return `seats/ctvNext/${seatId}/dealsPicklist;${toQueryString(params)}`;
            },
            providesTags: (_, __, { seatId }) => [{ type: SEAT_ALL_DEALS_PICK_LIST_TAG, id: seatId }],
        }),
        getSeatAllDealsCTVNext: builder.query<UIDeal, GetSeatAllDealsParams>({
            query: ({ seatId, ...rest }: GetSeatAllDealsParams) => {
                const params: Omit<GetSeatAllDealsParams, "seatId"> = {
                    page: 1,
                    maxResults: 100,
                    searchKeyword: "",
                    filterDealOrigin: "",
                    orderBy: "id",
                    isAscending: false,
                    ...rest,
                };

                return `seats/ctvNext/${seatId}/allDeals;${toQueryString(params)}`;
            },
            providesTags: (_, __, { seatId }) => [{ type: SEAT_ALL_DEALS_TAG, id: seatId }],
            transformResponse: (response: ServerDealResponse) => {
                const mappedResponse = {
                    ...response,
                    deals: response.deals
                        .filter(
                            (deal) =>
                                !(
                                    deal.deal.dealType.id === AdSourceTypeIds.UNRESERVED_FIXED_RATE &&
                                    deal.deal.priceModel?.id === PricingModels.TIERED_PRICE
                                )
                        )
                        .map((deal) => {
                            return {
                                ...deal.deal,
                                adSources: deal.dealListSources,
                                pacing: deal.dealPacing,
                            };
                        }),
                };
                return mappedResponse;
            },
        }),
        getBuyerStatus: builder.query<BuyerStatus[], void>({
            query: () => "/deals/dealBuyerStatus",
            providesTags: () => [BUYER_STATUS_TAG],
        }),
    }),
    overrideExisting: false,
});

export const {
    useCreateSeatDealLegacyMutation,
    useCreateSeatDealMutation,
    useCreateSeatDealAdSourceMutation,
    useGetDealsPicklistQuery,
    useGetDealByIdQuery,
    useLazyGetDealByIdQuery,
    useGetDealAdSourceByIdQuery,
    useGetDealOriginsQuery,
    useGetDealStatusQuery,
    useGetSeatAllDealsCTVNextQuery,
    usePrefetch: useDealsPrefetch,
    useUpdateDealMutation,
    useDeleteDealMutation,
    useGetBuyerStatusQuery,
} = dealsApi;
