import { LabeledValue } from "antd/lib/select";
import {
    ContentMetadataChannels,
    ContentMetadataContentLength,
    ContentMetadataGenreToken,
    ContentMetadataGenres,
    ContentMetadataNetworks,
    ContentMetadataProducers,
    ContentMetadataRatings,
    ContentMetadataTitles,
    ContentMetadataTvSeries,
    LabelValue,
    UNKNOWN,
} from "@app/core/services/console";
import { TargetingDimensionTypeLabels } from "../constants";

const checkForUnknownValue = (value: string | null, typename: string) => {
    if (value && value === UNKNOWN) {
        return `${typename}: ${value}`;
    }
    return value;
};

export const prefixContentMetadataLabelWithSeatName = (
    seatName: string | null,
    value: string | null,
    typename: string
) => (seatName ? `Seat Name: ${seatName} > ${value}` : checkForUnknownValue(value, typename));

export const contentLengthsToLabelValue = (targets: ContentMetadataContentLength[] | null): LabeledValue[] =>
    (targets || []).map((target) => ({
        value: JSON.stringify(target),
        label: checkForUnknownValue(target.name, TargetingDimensionTypeLabels.ContentLengths),
    }));

export const contentRatingsToLabelValue = (targets: ContentMetadataRatings[] | null): LabeledValue[] =>
    (targets || []).map((target) => ({
        value: String(target.name),
        label: prefixContentMetadataLabelWithSeatName(
            target.seatName,
            target.name,
            TargetingDimensionTypeLabels.ContentRatings
        ),
    }));

export const contentProducersToLabelValue = (targets: ContentMetadataProducers[] | null): LabeledValue[] =>
    (targets || []).map((target) => ({
        value: target.name || "",
        label: prefixContentMetadataLabelWithSeatName(
            target.seatName,
            target.name,
            TargetingDimensionTypeLabels.Producers
        ),
    }));

export const contentMetadataTitleToLabelValue = (
    contentMetadataTitles: ContentMetadataTitles[] | null
): LabeledValue[] =>
    (contentMetadataTitles || []).map((contentMetadataTitle) => ({
        value: JSON.stringify(contentMetadataTitle),
        label: `Title: ${contentMetadataTitle.videoTitle}, ID: ${contentMetadataTitle.videoId}`,
    }));

const getContentMetadataTvSeriesLabel = (series: ContentMetadataTvSeries) => {
    let label = "";

    if (series.seatName) {
        label = `${series.seatName} > `;
    }

    const parts: string[] = [];

    if (series.seriesName) {
        parts.push(`Series: ${series.seriesName}`);
    }

    if (series.season) {
        parts.push(`Season: ${series.season}`);
    }

    if (series.episode) {
        parts.push(`Episode:  ${series.episode}`);
    }

    return label.concat(parts.join(" > "));
};

const getContentMetadataTvSeriesValue = (series: ContentMetadataTvSeries) => JSON.stringify(series);

export const contentMetadataTvSeriesToLabelValue = (targets: ContentMetadataTvSeries[] | null): LabeledValue[] => {
    // TODO: This is a hack to remove duplicates. We should fix the API to not return duplicates
    // or to give us a primary key so we don't have to construct one
    const byValue = contentMetadataTvSeriesByValue(targets);

    return Object.values(byValue).map((target) => {
        return {
            value: getContentMetadataTvSeriesValue(target),
            label: getContentMetadataTvSeriesLabel(target),
        };
    });
};

export const contentMetadataTvSeriesByValue = (
    targets: ContentMetadataTvSeries[] | null
): { [value: string]: ContentMetadataTvSeries } => {
    return (targets || []).reduce((byValue, target) => {
        byValue[getContentMetadataTvSeriesValue(target)] = target;
        return byValue;
    }, {});
};

export const contentMetadataNetworksToLabelValue = (
    contentMetadataNetworks: ContentMetadataNetworks[] | string[] | null
): LabeledValue[] =>
    (contentMetadataNetworks || []).map((contentMetadataNetwork) => {
        if (typeof contentMetadataNetwork === "string") {
            return {
                value: contentMetadataNetwork,
                label: contentMetadataNetwork,
            };
        }
        return {
            value: String(contentMetadataNetwork.name),
            label: prefixContentMetadataLabelWithSeatName(
                contentMetadataNetwork.seatName,
                contentMetadataNetwork.name,
                TargetingDimensionTypeLabels.ContentNetworks
            ),
        };
    });

export const contentMetadataGenresToLabelValue = (
    contentMetadataGenres: ContentMetadataGenres[] | string[] | null
): LabeledValue[] =>
    (contentMetadataGenres || []).map((contentMetadataGenre) => {
        if (typeof contentMetadataGenre === "string") {
            return {
                value: contentMetadataGenre,
                label: contentMetadataGenre,
            };
        }
        return {
            value: contentMetadataGenre.name,
            label: prefixContentMetadataLabelWithSeatName(
                contentMetadataGenre.seatName,
                contentMetadataGenre.name,
                TargetingDimensionTypeLabels.Genres
            ),
        };
    });

export const contentMetadataGenreTokensToLabelValue = (
    contentMetadataGenres: ContentMetadataGenreToken[] | string[] | null
) => {
    return (contentMetadataGenres || []).map((contentMetadataGenre: ContentMetadataGenreToken | string) => {
        if (typeof contentMetadataGenre === "string") {
            return {
                value: contentMetadataGenre,
                label: contentMetadataGenre,
            };
        }
        return {
            value: contentMetadataGenre.name,
            label: prefixContentMetadataLabelWithSeatName(
                contentMetadataGenre.seat?.name || null,
                contentMetadataGenre.name,
                TargetingDimensionTypeLabels.Genres
            ),
        };
    });
};
export const contentMetadataContentChannelToLabelValue = (
    contentMetadataContentChannels: ContentMetadataChannels[] | string[] | null
): LabeledValue[] =>
    (contentMetadataContentChannels || []).map((channel) => {
        if (typeof channel === "string") {
            return {
                value: channel,
                label: channel,
            };
        }
        return {
            value: channel.name,
            label: prefixContentMetadataLabelWithSeatName(
                channel.seatName,
                channel.name,
                TargetingDimensionTypeLabels.ContentChannels
            ),
        };
    });

export const labelValuesToLabeledValues = (targets: LabelValue[] | null) => {
    return (targets || []).map((target) => {
        return {
            value: JSON.stringify(target),
            label: `${target.label.key}: ${target.value}`,
        };
    });
};
