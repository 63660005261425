import { format } from "@rubicon/utils";

const { NO, YES } = format.constants;

export enum DEAL_SECTIONS {
    CHARTS = "CHARTS",
    PACING_STATUS = "PACING_STATUS",
    DEAL_TERMS = "DEAL_TERMS",
    PRICING = "PRICING",
    DEMAND_CONFIGURATION = "DEMAND_CONFIGURATION",
    DEMAND_TARGETING = "DEMAND_TARGETING",
    AD_SOURCE_CONFIGURATION = "AD_SOURCE_CONFIGURATION",
    PERFORMANCE = "PERFORMANCE",
}

export const DEAL_SECTIONS_NAME = {
    [DEAL_SECTIONS.PERFORMANCE]: "Performance",
    [DEAL_SECTIONS.CHARTS]: "Charts",
    [DEAL_SECTIONS.PACING_STATUS]: "Pacing Status",
    [DEAL_SECTIONS.DEAL_TERMS]: "Deal Terms",
    [DEAL_SECTIONS.PRICING]: "Pricing",
    [DEAL_SECTIONS.DEMAND_CONFIGURATION]: "Demand Configuration",
    [DEAL_SECTIONS.DEMAND_TARGETING]: "Demand Targeting",
    [DEAL_SECTIONS.AD_SOURCE_CONFIGURATION]: "Ad Source Configuration",
};

export const DEAL_SECTION_KEYS = {
    [DEAL_SECTIONS.CHARTS]: "1",
    [DEAL_SECTIONS.PACING_STATUS]: "2",
    [DEAL_SECTIONS.DEAL_TERMS]: "3",
    [DEAL_SECTIONS.PRICING]: "4",
    [DEAL_SECTIONS.DEMAND_CONFIGURATION]: "5",
    [DEAL_SECTIONS.AD_SOURCE_CONFIGURATION]: "6",
};

export enum DEAL_DETAILS_DRAWER_LOCATION {
    DEALS_HEALTH = "deals/health",
    DEALS = "deals",
    DEALS_DETAILS = "deals/details",
    DETAILS_DRAWER = "details-drawer",
    DEAL_DETAILS_DRAWER = "deal-details-drawer",
    EDIT = "edit",
    COPY = "copy",
}

export const ASCENDING = "ascend";
export const DESCENDING = "descend";

export const TRANSPARENCY_SHARED_VALUE = "shared";
export const TRANSPARENCY_HIDDEN_VALUE = "hidden";
export const TRANSPARENCY_OVERRIDE_VALUE = "override";
export const TRANSPARENCY_MASKED_VALUE = "masked";

export const TRANSPARENCY_ALL_VALUE = "all";
export const TRANSPARENCY_SPECIFIC_VALUE = "specific";
export const TRANSPARENCY_NONE_VALUE = "none";

export const SORT_ORDER = {
    ASCENDING,
    DESCENDING,
};

export const PRICING_TYPE_OPTIONS = [
    { id: 1, name: "Fixed Price" },
    { id: 2, name: "Auction Price" },
];

export const PRICING_TIER_OVERFLOW_ALLOWED_OPTIONS = [
    {
        label: YES,
        value: true,
    },
    {
        label: NO,
        value: false,
    },
];

export const PACING_DEFAULT_ACTIVE_KEY = "1";
export const TARGETING_DEFAULT_ACTIVE_KEY = "7";
export const ENRICHMENT_COST_DEFAULT_ACTIVE_KEY = "8";

export const PRICING_TYPE_OPTIONS_LABEL_VALUE = PRICING_TYPE_OPTIONS.map((option) => ({
    value: option.id,
    label: option.name,
}));

export const DEAL_TYPE_PROGRAMMATIC_GUARANTEE = { id: 1, name: "Programmatic Guaranteed" };
export const DEAL_TYPE_FIXED_PRICE_DEAL = { id: 2, name: "Fixed Price Deal" };
export const DEAL_TYPE_AUCTION_PRICE_DEAL = { id: 3, name: "Auction Price Deal" };
export const DEAL_TYPE_LEGACY_DEAL = { id: 4, name: "Legacy Deal" };

// Omitted { id: "all", name: "All" } due to us treating null as "all"
export const DEAL_TYPE_OPTIONS = [
    DEAL_TYPE_PROGRAMMATIC_GUARANTEE,
    DEAL_TYPE_FIXED_PRICE_DEAL,
    DEAL_TYPE_AUCTION_PRICE_DEAL,
    DEAL_TYPE_LEGACY_DEAL,
];

export const DEAL_TYPE_OPTIONS_LABEL_VALUE = DEAL_TYPE_OPTIONS.map((option) => ({
    value: option.id,
    label: option.name,
}));

export const BUYER_STATUS_OPTIONS = [
    { id: "Proposed", name: "Proposed" },
    { id: "Accepted", name: "Accepted" },
    { id: "Cancelled", name: "Cancelled" },
    { id: "Rejected", name: "Rejected" },
];

export const BUYER_STATUS_OPTIONS_LABEL_VALUE = BUYER_STATUS_OPTIONS.map((option) => ({
    value: option.id,
    label: option.name,
}));

export const LIVE_STATS_OPTIONS = [
    { label: "On", value: true },
    { label: "Off", value: false },
];
export const ALWAYS_SEND_FLOOR_OPTIONS = [
    { label: YES, value: true },
    { label: NO, value: false },
];
export const DAILY_IMPRESSIONS_OPTIONS = [
    { label: YES, value: true },
    { label: NO, value: false },
];
export const REDISTRIBUTION_OPTIONS = [
    { label: YES, value: true },
    { label: NO, value: false },
];
export const TRANSPARENCY_OPTIONS = [
    { label: "Shared", value: TRANSPARENCY_SHARED_VALUE },
    { label: "Hidden", value: TRANSPARENCY_HIDDEN_VALUE },
];
export const OVERRIDE_TRANSPARENCY_OPTIONS = [
    ...TRANSPARENCY_OPTIONS,
    { label: "Override", value: TRANSPARENCY_OVERRIDE_VALUE },
];
export const MASKED_TRANSPARENCY_OPTIONS = [
    ...TRANSPARENCY_OPTIONS,
    { label: "Masked", value: TRANSPARENCY_MASKED_VALUE },
];
export const SPECIFIED_TRANSPARENCY_OPTIONS = [
    {
        label: "All",
        value: TRANSPARENCY_ALL_VALUE,
    },
    {
        label: "Specific",
        value: TRANSPARENCY_SPECIFIC_VALUE,
    },
    {
        label: "None",
        value: TRANSPARENCY_NONE_VALUE,
    },
];
export const WATERFALL_PRE_FILTER_OPTIONS = [
    {
        label: "Match Inventory Labels (s)",
        value: true,
    },
    {
        label: "All Inventory",
        value: false,
    },
];

export const DEAL_TYPES = {
    GUARANTEE: 6,
    FIXED: 2,
    AUCTION: 3,
} as const;

export const PRICE_MODELS = {
    FIXED: 1,
    TIERED: 2,
} as const;

export const FORM_DEAL_TYPES_OPTIONS = [
    {
        value: DEAL_TYPES.GUARANTEE,
        label: "Programmatic Guaranteed",
    },
    {
        value: DEAL_TYPES.FIXED,
        label: "Fixed Price Deal",
    },
    {
        value: DEAL_TYPES.AUCTION,
        label: "Auction Price Deal",
    },
];

// Deal form items name
export const CREATE_DEAL_FORM_ITEMS_NAME = {
    CONFIGURE_AD_SOURCE: "dealConfigureAdSource",
    CONSENT: "dealConsent",
    BUYER: "dealBuyer",
    CURRENCY: "dealCurrency",
    TRANSACTION_CURRENCY: "transactionCurrency",
    ENFORCEMENT: "enforcement",
    FREQUENCY_CAPPING: "frequencyCapping",
    DEAL_FREQUENCY_CAPPINGS: "dealFreqCappings",
    DSP: "dealDsp",
    SHARED_SEATS: "dealSharedSeats",
    SHARED_MARKEPLACES: "dealSharedMarketplaces",
    PUBLISHER_NAME: "dealPublisherName",
    PUBLISHER_EMAIL: "dealPublisherEmail",
    GUARANTEED: "dealGuaranteed",
    ID: "dealId",
    IMPRESSION: "dealImpression",
    NAME: "dealName",
    ORIGIN: "dealOrigin",
    TRANSACTION_TYPE: "dealTransactionType",
    PRICE_TYPE: "dealPriceType",
    PRICE_MODEL: "dealPricingModel",
    RATE: "dealRate",
    START_DATE: "dealStartDate",
    END_DATE: "dealEndDate",
    STATUS: "dealStatus",
    TIME_ZONE: "dealTimeZone",
    TYPE: "dealType",
    DEMAND_TARGETING: "demandTargeting",
    AD_SOURCE_ADDITIONAL_ADVERTISER_DOMAIN: "adSourceAdditionalAdvertiserDomain",
    AD_SOURCE_ADD_TO_EXISTING_ID: "adSourceAddToExistingId",
    AD_SOURCE_AD_RESPONSE_PRICE_OVERRIDE: "adResponsePriceOverrideType",
    AD_SOURCE_ALLOWED_EXTENDED_ID_TRANSPARENCIES: "adSourceAllowedExtendedIdTransparencies",
    AD_SOURCE_ALLOW_ALL_EXTENDED_ID: "adSourceAllowAllExtendedId",
    AD_SOURCE_ALLOW_AUDIENCE_LOCK: "adSourceAllowAudienceLock",
    AD_SOURCE_ALLOW_BUNDLE_ID: "adSourceAllowBundleId",
    AD_SOURCE_ALLOW_CONTENT: "adSourceAllowContent",
    AD_SOURCE_ALLOW_DOMAIN_NAME: "adSourceAllowDomainName",
    AD_SOURCE_ALLOW_IP: "adSourceAllowIp",
    AD_SOURCE_ALLOW_REF: "adSourceAllowRef",
    AD_SOURCE_ALLOW_SITE_NAME: "adSourceAllowSiteName",
    AD_SOURCE_ALLOW_SITE_PAGE: "adSourceAllowSitePage",
    AD_SOURCE_ALLOW_STORE_URL: "adSourceAllowStoreUrl",
    AD_SOURCE_ALLOW_USER_ID: "adSourceAllowUserId",
    AD_SOURCE_ALWAYS_SEND_FLOOR: "adSourceAlwaysSendFloor",
    AD_SOURCE_ASSIGNEE: "adSourceAssignee",
    AD_SOURCE_AUCTION_TYPE: "adSourceAuctionType",
    AD_SOURCE_AUTOSCALE: "adSourceAutoscale",
    AD_SOURCE_BOOKING_END_DATE: "adSourceBookingEndDate",
    AD_SOURCE_BOOKING_PRICE: "adSourceBookingPrice",
    AD_SOURCE_BOOKING_START_DATE: "adSourceBookingStartDate",
    AD_SOURCE_BUNDLE_ID_OVERRIDE: "adSourceBundleIdOverride",
    AD_SOURCE_CONTENT_TRANSPARENCY_RULE: "adSourceContentTransparencyRule",
    AD_SOURCE_COPY_ID: "adSourceCopyId",
    AD_SOURCE_COST: "adSourceCost",
    AD_SOURCE_COST_MODEL: "adSourceCostModel",
    AD_SOURCE_CURRENCY: "adSourceCurrency",
    AD_SOURCE_DEMAND_ACQUISITION_COST: "adSourceDemandAcquisitionCost",
    AD_SOURCE_DEMAND_ACQUISITION_COST_MODEL: "adSourceDemandAcquisitionCostModel",
    AD_SOURCE_DESCRIPTION: "adSourceDescription",
    AD_SOURCE_DOMAIN_NAME_OVERRIDE: "adSourceDomainNameOverride",
    AD_SOURCE_EXTEND_TIMEOUT: "adSourceExtendTimeout",
    AD_SOURCE_EXTERNAL_CONTACT_NUMBER: "adSourceExternalContactNumber",
    AD_SOURCE_FLOOR_TYPE: "adSourceFloorType",
    AD_SOURCE_IAB_CATEGORIES: "adSourceIabCategories",
    AD_SOURCE_IAB_CATEGORY_BLOCK_MODE: "adSourceIabCategoryBlockMode",
    AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES: "adSourceBlockExceptionIabCategories",
    AD_SOURCE_INDUSTRIES_MODE: "adSourceIndustryBlockMode",
    AD_SOURCE_BLOCKED_INDUSTRIES: "adSourceBlockedIndustries",
    AD_SOURCE_INVENTORY_DISTRIBUTION_GROUPS: "adSourceInventoryDistributionGroups",
    AD_SOURCE_LABELS: "adSourceLabels",
    AD_SOURCE_MAX_DURATION: "adSourceMaxDuration",
    AD_SOURCE_MIN_DURATION: "adSourceMinDuration",
    AD_SOURCE_MODE: "adSourceMode",
    AD_SOURCE_MODE_CONFIGURATION: "adSourceModeConfiguration",
    AD_SOURCE_NAME: "adSourceName",
    AD_SOURCE_PACING_CUSTOM_CURVE_IMPS: "adSourcePacingCustomCurveImps",
    AD_SOURCE_PACING_CUSTOM_CURVE_START_DATE: "adSourcePacingCustomCurveStartDate",
    AD_SOURCE_PACING_FALLBACK: "adSourcePacingFallback",
    AD_SOURCE_PACING_DAILY_IMPRESSION_CAP_ENABLED: "adSourcePacingDailyImpressionCapEnabled",
    AD_SOURCE_PACING_IMPRESSION_CAP: "adSourcePacingImpressionCap",
    AD_SOURCE_PACING_REDISTRIBUTE: "adSourcePacingRedistribute",
    AD_SOURCE_PACING_TYPE: "adSourcePacingType",
    AD_SOURCE_PIXELS: "adSourcePixels",
    AD_SOURCE_PRIORITY: "adSourcePriority",
    AD_SOURCE_REGION: "adSourceRegion",
    AD_SOURCE_REUSABLE_ADVERTISER_DOMAIN: "adSourceReusableAdvertiserDomain",
    AD_SOURCE_RUN_UNDER_INBOUND_FLOOR: "adSourceRunUnderInboundFloor",
    AD_SOURCE_SALES_CONTACT: "adSourceSalesContact",
    AD_SOURCE_STATUS: "adSourceStatus",
    AD_SOURCE_STRICT_MODE: "adSourceStrictMode",
    AD_SOURCE_TARGETING: "adSourceTargeting",
    AD_SOURCE_TIME_ZONE: "adSourceTimeZone",
} as const;

export const CREATE_AD_SOURCE_TYPES_NEW = "Define New Ad Source";
export const CREATE_AD_SOURCE_TYPES_ADD = "Add Deal to Existing Ad Source";
export const CREATE_AD_SOURCE_TYPES_COPY = "Copy an Existing Ad Source";

export const CREATE_AD_SOURCE_TYPES = {
    NEW: CREATE_AD_SOURCE_TYPES_NEW,
    ADD: CREATE_AD_SOURCE_TYPES_ADD,
    COPY: CREATE_AD_SOURCE_TYPES_COPY,
};

export const GUTTER = { xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 32 };
export const COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 8, xxl: 8 };
export const RANGE_COL_SIZES = { xs: 24, sm: 24, md: 6, lg: 6, xl: 4, xxl: 4 };

export const CREATE_DEAL_INVENTORY_FORM_ITEMS_NAME = {
    MEDIUM_TYPE: "inventoryMediumType",
    DESCRIPTION: "inventoryDescription",
    WEEKLY_IMPRESSIONS: "inventoryWeeklyImpressions",
    WEEKLY_UNIQUES: "inventoryWeeklyUniques",
    BREAKDOWNS: "inventoryBreakDowns",
    EXTERNAL_STATUS: "inventoryExternalStatus",
};

export const MEDIUM_TYPE = {
    UNSPECIFIED: { id: 1, name: "Unspecified" },
    DIGITAL: { id: 2, name: "Digital" },
    TV: { id: 3, name: "TV" },
};

export const MEDIUM_TYPE_LABEL_VALUE_OPTIONS = Object.values(MEDIUM_TYPE).map((type) => ({
    label: type.name,
    value: type.id,
}));

export const AUCTION_PACKAGE_DEAL_STATUSES = {
    UNSPECIFIED: { name: "Unspecified", id: "DISCOVERY_OBJECT_STATUS_UNSPECIFIED" },
    ACTIVE: { name: "Active", id: "DISCOVERY_OBJECT_STATUS_ACTIVE" },
    PAUSED: { name: "Paused", id: "DISCOVERY_OBJECT_STATUS_PAUSED" },
    ARCHIVED: { name: "Archived", id: "DISCOVERY_OBJECT_STATUS_ARCHIVED" },
    REMOVED: { name: "Removed", id: "DISCOVERY_OBJECT_STATUS_REMOVED" },
};

export const AUCTION_PACKAGE_DEAL_STATUSES_LABEL_VALUE_OPTIONS = Object.values(AUCTION_PACKAGE_DEAL_STATUSES).map(
    (status) => ({ label: status.name, value: status.id })
);

export enum DealsHelpKeys {
    AdStatsPublisherDealStatus = "adstats-publisher.dealStatus",
    AdStatsPublisherAdSourcePriority = "adstats-publisher.adSourcePriority",
    AdStatsPublisherDealName = "adstats-publisher.dealName",
    AdStatsPublisherImpressions = "adstats-publisher.impressions",
    AdStatsPublisherUseRate = "adstats-publisher.useRate",
    AdStatsPublisherNetRevenue = "adstats-publisher.netRevenue",
    DealBuyerStatus = "dealdetails.buyerstatus",
    DealStatus = "deal.status",
    DealDetailsStatus = "dealdetails.status",
    DealType = "deal.type",
    DealBuyer = "deal.buyer",
    DealDetailsType = "dealdetails.dealtype",
    DealDSP = "deal.demand",
    DealPricingType = "deal.pricingtype",
    DealCurrency = "deal.currencytype",
    DealPriceModel = "deal.pricemodel",
    DealTransactionCurrency = "deal.transactioncurrency",
    DealEnforcement = "deal.enforcement",
    DealFreqCapping = "deal.freqcapping",
    DealPriority = "deal.priority",
    DealDetailsPriority = "dealdetails.priority",
    DealName = "deal.name",
    DealDetailsPacing = "dealdetails.pacing",
    DealDetailsCPMRate = "dealdetails.cpmrate",
    DealDates = "deal.dates",
    DealOrigin = "deal.origin",
    DealId = "deal.dealid",
    DealLadle = "tools.ladler",
    DealTimeZone = "deal.timezone",
    LadleRegion = "adserverladle.region",
    LadleName = "adserverladle.name",
    LadleSupplyDomain = "adserverladle.supplydomain",
    LadleAdomain = "adserverladle.adomain",
    LadleCreativeID = "adserverladle.creativeid",
    LadleCreative = "adserverladle.creative",
    LadleHasCreative = "adserverladle.hascreative",
    LadleDemand = "adserverladle.demand",
    //Stats(Charts)
    Skips = "dealdetails.skips",
    Tries = "dealdetails.tries",
    Fills = "dealdetails.fills",
    Impressions = "dealdetails.impressions",
    NetRevenue = "dealdetails.netrevenue",
    //Pacing Status
    RecordedImp = "pacingdiags.recordedimps",
    Projected = "pacingdiags.projectedimps",
    OnPace = "pacingdiags.onpace",
    EstDailyAvails = "pacingdiags.dailyavails",
}

export enum AdSourceHelpKeys {
    AdSourcePrefilterMode = "adsource.prefiltermode",
    AdSourceLabels = "adsource.labels",
    AdSourcePriority = "adsource.proprity",
    AdSourceExtendTimeout = "adsource.nurltimeoutoverride",
    AdSourceAssignee = "adsource.assignee",
    //Pacing
    AdSourcePacingPeriod = "adsource.pacingperiod",
    AdSourcePacingType = "adsource.pacingtype",
    AdSourceDailyImpressionCapEnabled = "adsource.dailycapenabled",
    //Delivery Details
    AdSourceStartDate = "adsource.bookingstartdate",
    AdSourceEndDate = "adsource.bookingenddate",
    AdSourceTimeZone = "adsource.timezone",
    AdSourceTotalImpressions = "adsource.bookingvolume",
    AdSourceMinDuration = "adsource.minduration",
    AdSourceMaxDuration = "adsource.maxduration",
    //Brand Safety
    AdSourceMode = "adsource.advertiserblockingmode",
    AdSourceReusableAdvertiserDomain = "adsource.reusablelistdomains",
    AdSourceAdditionalAdvertiserDomain = "adsource.staticlistdomain",
    //Custom Pixel
    AdSourcePixel = "adsource.thirdpartypixels",
    //Transparency
    AdSourceSupplyName = "adsource.supplyname",
    AdSourceDomainName = "adsource.domainnameallowed",
    AdSourceSupplyPage = "adsource.supplypageallowed",
    AdSourceReferURL = "adsource.refallowed",
    AdSourceFullIpAddress = "adsource.ipallowed",
    AdSourceBundleId = "adsource.bundleallowed",
    AdSourceStoreURL = "adsource.storeallowed",
    AdSourceUserIDIFA = "adsource.useridallowed",
    AdSourceContent = "adsource.contentallowed",
    AdSourceIncludedExtendedIds = "adsource.extendedid",
}

export const DEAL_LADLE_HELP_FIELDS = [
    {
        label: "Region",
        helpPath: DealsHelpKeys.LadleRegion,
        key: "REGION",
    },
    {
        label: "Ladle Name",
        helpPath: DealsHelpKeys.LadleName,
        key: "NAME",
    },
    {
        label: "Supply Domain",
        helpPath: DealsHelpKeys.LadleSupplyDomain,
        key: "SUPPLY_DOMAIN",
    },
    {
        label: "Advertiser Domain",
        helpPath: DealsHelpKeys.LadleAdomain,
        key: "ADVERTISER_DOMAIN",
    },
    {
        label: "Creative ID",
        helpPath: DealsHelpKeys.LadleCreativeID,
        key: "CREATIVE_ID",
    },
    {
        label: "Creative",
        helpPath: DealsHelpKeys.LadleCreative,
        key: "CREATIVE",
    },
    {
        label: "Demand",
        helpPath: DealsHelpKeys.LadleDemand,
        key: "DEMAND",
    },
];

export const DEAL_STATS_HELP_FIELDS = [
    {
        label: "Skips",
        helpPath: DealsHelpKeys.Skips,
        key: "SKIPS",
    },
    {
        label: "Tries",
        helpPath: DealsHelpKeys.Tries,
        key: "TRIES",
    },
    {
        label: "Fills",
        helpPath: DealsHelpKeys.Fills,
        key: "FILLS",
    },
    {
        label: "Impressions",
        helpPath: DealsHelpKeys.Impressions,
        key: "IMPRESSIONS",
    },
    {
        label: "Net Revenue",
        helpPath: DealsHelpKeys.NetRevenue,
        key: "NET_REVENUE",
    },
];

export const DEAL_PACING_STATUS_HELP_FIELDS = [
    {
        label: "Recorded Imp",
        helpPath: DealsHelpKeys.RecordedImp,
        key: "RECORDCEDIMP",
    },
    {
        label: "Projected",
        helpPath: DealsHelpKeys.Projected,
        key: "PROJECTED",
    },
    {
        label: "On Pace",
        helpPath: DealsHelpKeys.OnPace,
        key: "ONPACE",
    },
    {
        label: "Est. Daily Avails",
        helpPath: DealsHelpKeys.EstDailyAvails,
        key: "ESTDAILYAVAILS",
    },
];

export const AD_SOURCE_PACING_HELP_FIELDS = [
    {
        label: "Pacing Period",
        helpPath: AdSourceHelpKeys.AdSourcePacingPeriod,
        key: "pacingPeriod",
    },
    {
        label: "Pacing Type",
        helpPath: AdSourceHelpKeys.AdSourcePacingType,
        key: "pacingType",
    },
    {
        label: "Daily Impression Cap Enabled",
        helpPath: AdSourceHelpKeys.AdSourceDailyImpressionCapEnabled,
        key: "impressionCapEnabled",
    },
];

export const AD_SOURCE_HELP_DELIVERY_DETAILS_HELP_FIELDS = [
    {
        label: "Start Date",
        helpPath: AdSourceHelpKeys.AdSourceStartDate,
        key: "adSourceStartDate",
    },
    {
        label: "End Date",
        helpPath: AdSourceHelpKeys.AdSourceEndDate,
        key: "adSourceEndDate",
    },
    {
        label: "Time Zone",
        helpPath: AdSourceHelpKeys.AdSourceTimeZone,
        key: "timeZone",
    },
];
export const AD_SOURCE_BRAND_SAFETY_HELP_FIELDS = [
    {
        label: "Mode",
        helpPath: AdSourceHelpKeys.AdSourceMode,
        key: "mode",
    },
    {
        label: "Reusable Advertiser Domain",
        helpPath: AdSourceHelpKeys.AdSourceReusableAdvertiserDomain,
        key: "advertiserDomain",
    },
    {
        label: "Additional Advertiser Domains",
        helpPath: AdSourceHelpKeys.AdSourceAdditionalAdvertiserDomain,
        key: "additionalDomain",
    },
];
export const AD_SOURCE_CUSTOM_PIXEL_HELP_FIELDS = [
    {
        label: "Pixel",
        helpPath: AdSourceHelpKeys.AdSourcePixel,
        key: "pixel",
    },
];
export const AD_SOURCE_TRANSPARENCY_HELP_FIELDS = [
    {
        label: "Supply Name/App Name",
        helpPath: AdSourceHelpKeys.AdSourceSupplyName,
        key: "supplyName",
    },
    {
        label: "Domain Name",
        helpPath: AdSourceHelpKeys.AdSourceDomainName,
        key: "domainName",
    },
    {
        label: "Supply Page/Content URL",
        helpPath: AdSourceHelpKeys.AdSourceSupplyPage,
        key: "supplyPage",
    },
    {
        label: "Refer URL",
        helpPath: AdSourceHelpKeys.AdSourceReferURL,
        key: "referUrl",
    },
    {
        label: "Full IP Address",
        helpPath: AdSourceHelpKeys.AdSourceFullIpAddress,
        key: "fullIpAddress",
    },
    {
        label: "Bundle ID",
        helpPath: AdSourceHelpKeys.AdSourceBundleId,
        key: "bundleId",
    },
    {
        label: "Store URL",
        helpPath: AdSourceHelpKeys.AdSourceStoreURL,
        key: "storeUrl",
    },
    {
        label: "User ID/IFA",
        helpPath: AdSourceHelpKeys.AdSourceUserIDIFA,
        key: "userIdIfa",
    },
];
export const AD_SOURCE_LADLES_HELP_FIELDS = [
    {
        label: "Waterfall Pre-Filter Mode",
        helpPath: AdSourceHelpKeys.AdSourcePrefilterMode,
        key: "preFilterMode",
    },
    {
        label: "Label",
        helpPath: AdSourceHelpKeys.AdSourceLabels,
        key: "labels",
    },
];
export const AD_SOURCE_CONFIGURATION_HELP_FIELDS = [
    {
        label: "Priority",
        helpPath: AdSourceHelpKeys.AdSourcePriority,
        key: "priority",
    },
    {
        label: "Extend Timeout",
        helpPath: AdSourceHelpKeys.AdSourceExtendTimeout,
        key: "extendTimeout",
    },
    {
        label: "Assignee",
        helpPath: AdSourceHelpKeys.AdSourceAssignee,
        key: "assignee",
    },
    ...AD_SOURCE_PACING_HELP_FIELDS,
    ...AD_SOURCE_HELP_DELIVERY_DETAILS_HELP_FIELDS,
    ...AD_SOURCE_BRAND_SAFETY_HELP_FIELDS,
    ...AD_SOURCE_CUSTOM_PIXEL_HELP_FIELDS,
    ...AD_SOURCE_TRANSPARENCY_HELP_FIELDS,
    ...AD_SOURCE_LADLES_HELP_FIELDS,
];

export enum DealDetailsDrawerTitles {
    ChangeHistory = "Change History",
    Ladle = "Ladle",
    Performance = "Performance", //TODO this related to charts drawer
}

export const dealDetailsHelpKeys = {
    [DealDetailsDrawerTitles.Ladle]: DEAL_LADLE_HELP_FIELDS,
    [DealDetailsDrawerTitles.Performance]: DEAL_STATS_HELP_FIELDS,
};

export const DEAL_NAME_MAX_LENGTH = 250;

export const AUDIENCE_LOCK_AD_SOURCE_ENABLED_ID = 3;

export const FREQUENCY_CAPPING_OFF_ID = 1;

export const AUCTION_PRICE_CPM_HELP_TEXT =
    "CPM Rate on this Deal will only be used in Deal syncs with participating DSPs. Actual auction floors will rely on Ad Source settings.";
