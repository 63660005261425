import { Button, Dropdown, Flex, Space } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useDetailsDrawerUnitLayoutToolbar } from "./useDetailsDrawerUnitLayoutToolbar";
import { SupplyTrafficSwitch } from "@app/features/inventory/components/SupplyTrafficSwitch";

export const DetailsDrawerUnitLayoutToolbar = () => {
    const {
        handleEdit,
        handleCopy,
        handleCreativeReview,
        handleDiagnostics,
        handlePermissions,
        handleAddBrand,
        handleAddChannel,
        handleAddSupply,
        handleAddAdUnit,
        handleTagParams,
        moreMenuItems,
        hasSeatWriteAccess,
        isPublisher,
        isChannel,
        isBrand,
        isSupply,
        isCopyAvailable,
    } = useDetailsDrawerUnitLayoutToolbar();

    return (
        <Flex
            justify="space-between"
            data-sdet="inventory-details-drawer-toolbar"
            align="baseline"
            wrap="wrap"
            gap="middle"
        >
            {hasSeatWriteAccess && (
                <Space>
                    <Button.Group>
                        <Button onClick={handleEdit} type="primary">
                            Edit
                        </Button>
                        {isCopyAvailable && <Button onClick={handleCopy}>Copy</Button>}
                    </Button.Group>
                    {isSupply && <SupplyTrafficSwitch key="trafficSwitch" />}
                </Space>
            )}
            <Button.Group>
                <Button onClick={handleTagParams}>Inbound Request Params</Button>
                <Button onClick={handleCreativeReview}>Creative Review</Button>
                <Button onClick={handleDiagnostics}>Diagnostics</Button>
                {(isPublisher || isChannel) && <Button onClick={handlePermissions}>Permissions</Button>}

                {isPublisher && hasSeatWriteAccess && <Button onClick={handleAddChannel}>Add Channel</Button>}
                {isPublisher && hasSeatWriteAccess && <Button onClick={handleAddBrand}>Add Brand</Button>}
                {isBrand && hasSeatWriteAccess && <Button onClick={handleAddSupply}>Add Supply</Button>}
                {isSupply && hasSeatWriteAccess && <Button onClick={handleAddAdUnit}>Add Ad Unit</Button>}

                <Dropdown trigger={["click"]} menu={{ items: moreMenuItems }}>
                    <Button icon={<MoreOutlined rotate={90} />} />
                </Dropdown>
            </Button.Group>
        </Flex>
    );
};
