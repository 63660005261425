import { SeatAccessFlagsTypes } from "@app/core/auth";
import { Icons } from "@rubicon/antd-components";
import { NavMenuItemProps } from "../Nav";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { SideNavBetaLabel } from "./BetaLabel";

export const getMenuItems = (
    {
        hasAdServingAccess,
        hasAdSourcesAccess,
        hasDealsAccess,
        hasDiagnosticsAccess,
        hasDiagnosticsBetaAccess,
        hasIntegrationsAccess,
        hasInventoryAccess,
        hasSeatControlsAccess,
        hasCreativeReviewBetaAccess,
    }: SeatAccessFlagsTypes,
    seatId: number,
    hasControlsNavItems: boolean
) => {
    return [
        {
            key: "seat-dashboard",
            label: "Dashboard",
            icon: <Icons.DashboardIcon />,
            dataSdet: "Dashboard",
            route: ROUTE_FORMATTERS.SEAT_DASHBOARD(seatId),
        },
        hasDealsAccess && {
            key: "deals",
            label: "Deals",
            dataSdet: "Deals",
            icon: <Icons.CampaignsIcon />,
            route: ROUTE_FORMATTERS.SEAT_DEAL_HEALTH(seatId),
        },
        hasInventoryAccess && {
            key: "inventory",
            label: "Inventory",
            dataSdet: "Inventory",
            icon: <Icons.InventoryIcon />,
            route: ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH(seatId),
        },
        hasAdServingAccess && {
            key: "ad-serving",
            label: "Ad Serving",
            dataSdet: "Ad Serving",
            icon: <Icons.AdServingIcon />,
            route: ROUTE_FORMATTERS.SEAT_AD_SERVING(seatId),
        },
        hasAdSourcesAccess && {
            key: "ad-sources",
            label: "Ad Sources",
            dataSdet: "Ad Sources",
            icon: <Icons.AdSourcesIcon />,
            route: ROUTE_FORMATTERS.SEAT_AD_SOURCES(seatId),
        },
        {
            key: "reports",
            label: "Reports",
            dataSdet: "Reports",
            icon: <Icons.ReportingIcon />,
            route: ROUTE_FORMATTERS.SEAT_REPORTS(seatId),
        },
        hasSeatControlsAccess &&
            hasControlsNavItems && {
                key: "controls",
                label: "Controls",
                dataSdet: "Controls",
                icon: <Icons.ControlsIcon />,
                route: ROUTE_FORMATTERS.SEAT_CONTROLS(seatId),
            },
        hasDiagnosticsAccess && {
            key: "seat-diagnostics",
            label: "Diagnostics",
            dataSdet: "Diagnostics",
            icon: <Icons.DiagnosticsIcon />,
            route: ROUTE_FORMATTERS.SEAT_DIAGNOSTICS(seatId),
        },
        hasDiagnosticsBetaAccess && {
            key: "diagnostics",
            dataSdet: "Diagnostics Beta",
            label: <SideNavBetaLabel title="Diagnostics" />,
            icon: <Icons.DiagnosticsIcon />,
            route: ROUTE_FORMATTERS.DIAGNOSTICS(seatId),
        },
        hasIntegrationsAccess && {
            key: "integrations-console",
            label: "Integrations",
            dataSdet: "Integrations",
            icon: <Icons.IntegrationsIcon />,
            route: ROUTE_FORMATTERS.SEAT_INTEGRATIONS_CONSOLE(seatId),
        },
        hasCreativeReviewBetaAccess && {
            key: "creative-review",
            label: "Creative Review",
            dataSdet: "Creative Review",
            icon: <Icons.CreativeReviewIcon />,
            route: ROUTE_FORMATTERS.SEAT_CREATIVE_REVIEW(seatId),
        },
        hasIntegrationsAccess && {
            key: "integrations",
            label: <SideNavBetaLabel title="Integrations" />,
            icon: <Icons.IntegrationsIcon />,
            route: ROUTE_FORMATTERS.SEAT_INTEGRATIONS(seatId),
        },
    ].filter(Boolean) as {
        key: string;
        label: string;
        dataSdet: string;
        icon: JSX.Element;
        route: string;
    }[];
};

export const getMoreMenuItems = (
    handleMenuClose: () => void,
    openHelpCenter: (openInNewWindow: boolean, isSeat: boolean) => Promise<unknown>
): NavMenuItemProps[] => [
    {
        id: "help-center",
        title: "Help Center",
        description: "Find product documentation, developer resources, and platform information.",
        onClick: () => {
            openHelpCenter(true, true).finally(handleMenuClose);
        },
    },
];
