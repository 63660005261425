import { FC } from "react";
import { AdSource, SeatContentTransparencyRule } from "@app/core/services";
import { Col, Row } from "antd";
import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { DetailsItem } from "../DetailsItem";
import { format } from "@rubicon/utils";
import { Dashes } from "@app/core/components";
import { DealAdSourceTransparencyExtendedUserId } from "@app/features/deals/DealForm/types";

interface Props {
    adSource: AdSource;
}

const sharedOrHiddenOrRule = (allow: boolean, rule: SeatContentTransparencyRule | null) => {
    return rule ? rule.name : format.asSharedOrHidden(allow);
};

const sharedOrHiddenOrMasked = (allow: boolean, mask: boolean) => {
    return mask ? "Masked" : format.asSharedOrHidden(allow);
};

const extendedIdShared = (allow: boolean, ids: unknown[]) => {
    if (allow && !ids?.length) {
        return "Yes";
    }
    return allow || format.asYesOrNo(ids?.length);
};

const extendedIdTransparencies = (allow: boolean, ids: DealAdSourceTransparencyExtendedUserId[]) => {
    if (allow) {
        return "All";
    }
    if (!ids?.length) {
        return "None";
    }
    return ids.map((extendedIdTransparency) => (
        <li key={extendedIdTransparency.id}>
            {extendedIdTransparency.extendedId?.name}
            {extendedIdTransparency.network ? " - " + extendedIdTransparency.network.name : ""}
        </li>
    ));
};

export const SeatAdSourcesTransparencyDetails: FC<Props> = ({ adSource }) => {
    return (
        <Row>
            <Col sm={8}>
                <DetailsItem label="Supply Name/App Name">
                    {format.asSharedOrHidden(adSource.allowSiteName)}
                </DetailsItem>
                <DetailsItem label="Supply Page/Content URL">
                    {format.asSharedOrHidden(adSource.allowSitePage) +
                        (adSource.domainNameOverride ? " - " + adSource.domainNameOverride : "")}
                </DetailsItem>
                <DetailsItem label="IP Address">{format.asSharedOrHidden(adSource.allowIp)}</DetailsItem>
                <DetailsItem label="Store URL">{format.asSharedOrHidden(adSource.allowStoreUrl)}</DetailsItem>
                {adSource.type?.id != AdSourceTypeIds.OPEN_AUCTION && (
                    <DetailsItem label="Share Extended IDs">
                        {extendedIdShared(adSource.allowAllExtendedId, adSource.allowedExtendedIdTransparencies)}
                    </DetailsItem>
                )}
            </Col>
            <Col sm={8}>
                <DetailsItem label="Domain Name">{format.asSharedOrHidden(adSource.allowDomainName)}</DetailsItem>
                <DetailsItem label="Refer URL">
                    {format.asSharedOrHidden(adSource.allowRef) +
                        (adSource.domainNameOverride ? " - " + adSource.domainNameOverride : "")}
                </DetailsItem>
                <DetailsItem label="Bundle ID">{format.asSharedOrHidden(adSource.allowBundleId)}</DetailsItem>
                <DetailsItem label="User ID/FA">
                    {sharedOrHiddenOrMasked(adSource.allowUserId, adSource.maskUserId)}
                </DetailsItem>
                {adSource.type?.id != AdSourceTypeIds.OPEN_AUCTION && (
                    <DetailsItem label="Included Extended IDs">
                        {extendedIdTransparencies(
                            adSource.allowAllExtendedId,
                            adSource.allowedExtendedIdTransparencies
                        )}
                    </DetailsItem>
                )}
            </Col>
            <Col sm={8}>
                <DetailsItem label="Domain Name Override">
                    <Dashes value={adSource.domainNameOverride} />
                </DetailsItem>
                <DetailsItem label="Bundle ID Override">
                    <Dashes value={adSource.bundleIdOverride} />
                </DetailsItem>
                <DetailsItem label="Content">
                    {sharedOrHiddenOrRule(adSource.allowContent, adSource.contentTransparencyRule)}
                </DetailsItem>
                <DetailsItem label="Allow Data Lock">{format.asYesOrNo(adSource.allowAudienceLock)}</DetailsItem>
            </Col>
        </Row>
    );
};
