import { LabeledValue } from "antd/lib/select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Targeting, TargetingMode } from "@app/core/services/console";
import {
    Conditions,
    CUSTOM_RULE_ID_TO_FIELD,
    CUSTOM_RULE_ID_TO_INPUT,
    CUSTOM_RULE_ID_TO_TYPE,
    CustomRuleFields,
    CustomRuleIds,
    CustomRuleOperators,
    DEFAULT_SUPPLY_TYPE,
    GroupConditions,
    Operations,
    PmpConditions,
    SegmentRuleIdentifierOperators,
    SegmentRuleModes,
    TargetingDimensionTypes,
    TargetingFormKeys,
    TimeZoneModeIds,
} from "./constants";
import {
    CustomRuleIdentifier,
    CustomRuleGroup,
    SegmentRuleGroup,
    TargetingBlock,
    TargetingDimension,
    TargetingStub,
    UsedDimensions,
} from "./types";
import {
    getDefaultSegmentRule,
    getDefaultSegmentRuleGroup,
    targetingModePayloadToTargetingOperation,
    targetingToTargetingBlock,
    toTargetingBlock,
    updateDimensionValuesFromSegmentRuleGroup,
} from "./helpers";

export interface TargetingFormState {
    targetingBlocks: (Targeting | TargetingStub | TargetingBlock)[];
    targetingOperation: Operations;
    validationTargetingBlocks: (Targeting | TargetingStub | TargetingBlock)[];
}

export interface TargetingState {
    targetingFormsByKey: {
        [TargetingFormKeys.AdSource]: TargetingFormState;
        [TargetingFormKeys.Deal]: TargetingFormState;
        [TargetingFormKeys.DealAdditionalTargeting]: TargetingFormState;
        [TargetingFormKeys.AdSourceAdditionalTargeting]: TargetingFormState;
        [TargetingFormKeys.InventoryAdvancedFloors]: TargetingFormState;
        [TargetingFormKeys.BrandSafety]: TargetingFormState;
        [TargetingFormKeys.Controls]: TargetingFormState;
    };
}

export const initialState: TargetingState = {
    targetingFormsByKey: {
        [TargetingFormKeys.AdSource]: {
            targetingBlocks: [],
            targetingOperation: Operations.All,
            validationTargetingBlocks: [],
        },
        [TargetingFormKeys.AdSourceAdditionalTargeting]: {
            targetingBlocks: [],
            targetingOperation: Operations.All,
            validationTargetingBlocks: [],
        },
        [TargetingFormKeys.Deal]: {
            targetingBlocks: [],
            targetingOperation: Operations.All,
            validationTargetingBlocks: [],
        },
        [TargetingFormKeys.DealAdditionalTargeting]: {
            targetingBlocks: [],
            targetingOperation: Operations.All,
            validationTargetingBlocks: [],
        },
        [TargetingFormKeys.InventoryAdvancedFloors]: {
            targetingBlocks: [],
            targetingOperation: Operations.All,
            validationTargetingBlocks: [],
        },
        [TargetingFormKeys.BrandSafety]: {
            targetingBlocks: [],
            targetingOperation: Operations.All,
            validationTargetingBlocks: [],
        },
        [TargetingFormKeys.Controls]: {
            targetingBlocks: [],
            targetingOperation: Operations.All,
            validationTargetingBlocks: [],
        },
    },
};

const getDefaultCustomRuleGroup = (): CustomRuleGroup => ({
    condition: GroupConditions.And,
    rules: [getDefaultCustomRule()],
    valid: true,
    readonly: false,
});

const getDefaultCustomRule = (): CustomRuleIdentifier => ({
    id: CustomRuleIds.CustomString,
    field: CustomRuleFields.CustomString,
    type: "string",
    input: "textarea",
    operator: CustomRuleOperators.Equal,
    value: null,
    readonly: false,
});

const getNewDimension = (type: TargetingDimensionTypes, usedDimensions: UsedDimensions): TargetingDimension => {
    switch (type) {
        case TargetingDimensionTypes.Audiences:
            return {
                type,
                condition: usedDimensions[type].includes ? Conditions.Excludes : Conditions.Includes,
                values: null,
            } as TargetingDimension;
        case TargetingDimensionTypes.MinDurationTarget:
        case TargetingDimensionTypes.MaxDurationTarget:
            return {
                type,
                condition: usedDimensions[type].includes ? Conditions.Excludes : Conditions.Includes,
                values: null,
            };
        case TargetingDimensionTypes.Pmp:
            return {
                type,
                condition: Conditions.Excludes,
                pmpCondition: PmpConditions.ExcludesSpecific,
                values: [],
            };
        case TargetingDimensionTypes.Coppa:
        case TargetingDimensionTypes.Dnt:
        case TargetingDimensionTypes.LiveStream:
            return {
                type,
                condition: Conditions.Includes,
                values: [true],
            };
        case TargetingDimensionTypes.SegmentRules:
            return {
                type,
                condition: usedDimensions[type].includes ? Conditions.Excludes : Conditions.Includes,
                mode: SegmentRuleModes.Simple,
                values: [],
                ruleGroup: getDefaultSegmentRuleGroup(),
            };
        case TargetingDimensionTypes.CustomRules:
            return {
                type,
                condition: usedDimensions[type].includes ? Conditions.Excludes : Conditions.Includes,
                ruleGroup: getDefaultCustomRuleGroup(),
            };
        case TargetingDimensionTypes.DayPartTargets:
            return {
                type,
                condition: usedDimensions[type].includes ? Conditions.Excludes : Conditions.Includes,
                timeZoneMode: TimeZoneModeIds.UTC,
                values: [],
            };
        case TargetingDimensionTypes.SupplyTypes: {
            return {
                type,
                condition: usedDimensions[type].includes ? Conditions.Excludes : Conditions.Includes,
                values: [DEFAULT_SUPPLY_TYPE],
            } as TargetingDimension;
        }
        case TargetingDimensionTypes.AdBreakPositionTargets:
        case TargetingDimensionTypes.ContentCategories:
        case TargetingDimensionTypes.ContentChannels:
        case TargetingDimensionTypes.ContentLengths:
        case TargetingDimensionTypes.ContentNetworks:
        case TargetingDimensionTypes.ContentRatings:
        case TargetingDimensionTypes.ContentSeries:
        case TargetingDimensionTypes.Genres:
        case TargetingDimensionTypes.Inventory:
        case TargetingDimensionTypes.Producers:
        case TargetingDimensionTypes.VideoIds:
        // Segments handled in SegmentRules case - adding here for type safety
        case TargetingDimensionTypes.Segments:
        // TimeZoneMode handled in DayPartTargets case - adding here for type safety
        case TargetingDimensionTypes.TimeZoneMode:
        default:
            return {
                type,
                condition: usedDimensions[type].includes ? Conditions.Excludes : Conditions.Includes,
                values: [],
            } as TargetingDimension;
    }
};

export const getUsedDimensionsDefault = (): UsedDimensions => ({
    [TargetingDimensionTypes.AdBreakPositionTargets]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.Audiences]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.ContentCategories]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.ContentChannels]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.ContentLengths]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.ContentNetworks]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.ContentRatings]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.ContentSeries]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.CustomRules]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.DayPartTargets]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.Genres]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.LabelValues]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.PodSlotPositionTargets]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.Producers]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.VideoIds]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.ApiFrameworks]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.Categories]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.GeoTargets]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.MimeTypes]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.OperatingSystems]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.Platforms]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.SegmentRules]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.Sizes]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.SupplyDomainTargets]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.SupplyTypes]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.BundleIdTargets]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.MinDurationTarget]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.MaxDurationTarget]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.Coppa]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.Dnt]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.LiveStream]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.Pmp]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.Bvod]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.CustomTargets]: {
        includes: false,
        excludes: false,
    },
    [TargetingDimensionTypes.Inventory]: {
        includes: false,
        excludes: false,
    },
});

const getUsedDimensions = (dimensions: TargetingDimension[]): UsedDimensions => {
    return dimensions.reduce<UsedDimensions>((acc, dimension) => {
        acc[dimension.type][dimension.condition] = true;
        return acc;
    }, getUsedDimensionsDefault());
};

export const getNewTargetingBlock = () => ({
    id: null,
    name: null,
    isReusable: false,
    isReusableModalOpen: false,
    dimensions: [],
    usedDimensions: getUsedDimensionsDefault(),
});

const targetingSlice = createSlice({
    name: "targeting",
    initialState,
    reducers: {
        setTargetingBlocks: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targeting: (Targeting | TargetingStub | TargetingBlock)[];
            }>
        ) => {
            state.targetingFormsByKey[action.payload.formKey].targetingBlocks = action.payload.targeting;
        },
        addExistingTargetingBlock: (
            state,
            action: PayloadAction<{ formKey: TargetingFormKeys; targeting: Targeting | TargetingStub }>
        ) => {
            state.targetingFormsByKey[action.payload.formKey].targetingBlocks = [
                ...state.targetingFormsByKey[action.payload.formKey].targetingBlocks,
                action.payload.targeting,
            ];
        },
        addTargetingBlock: (state, action: PayloadAction<{ formKey: TargetingFormKeys }>) => {
            state.targetingFormsByKey[action.payload.formKey].targetingBlocks = [
                ...state.targetingFormsByKey[action.payload.formKey].targetingBlocks,
                getNewTargetingBlock(),
            ];
        },
        replaceTargetingBlock: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targetingBlockIndex: number;
                targeting: Targeting;
            }>
        ) => {
            state.targetingFormsByKey[action.payload.formKey].targetingBlocks[action.payload.targetingBlockIndex] = {
                ...action.payload.targeting,
                exclude: action.payload.targeting.exclude
                    ? { ...action.payload.targeting.exclude, pmpDealIdTargets: null }
                    : null,
            };
        },
        addTargetingBlockDimension: (
            state,
            action: PayloadAction<{ formKey: TargetingFormKeys; index: number; type: TargetingDimensionTypes }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.index
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = [
                ...updatedTargetingBlock.dimensions,
                getNewDimension(action.payload.type, updatedTargetingBlock.usedDimensions),
            ];
            updatedTargetingBlock.usedDimensions = getUsedDimensions(updatedTargetingBlock.dimensions);
        },
        addTargetingBlockDimensionRule: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targetingBlockIndex: number;
                dimensionIndex: number;
                path: number[];
            }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = updatedTargetingBlock.dimensions.map((dimension, i) => {
                if (i === action.payload.dimensionIndex) {
                    // Duplicate logic with next block, but TS doesn't like the push
                    if (dimension.type === TargetingDimensionTypes.CustomRules) {
                        const updatedGroup = action.payload.path.reduce((acc, idx) => {
                            return acc.rules[idx];
                        }, dimension.ruleGroup);

                        if ("rules" in updatedGroup) {
                            updatedGroup.rules.push(getDefaultCustomRule());
                        }
                    }
                    if (dimension.type === TargetingDimensionTypes.SegmentRules) {
                        const updatedGroup = action.payload.path.reduce((acc, idx) => {
                            return acc.rules[idx];
                        }, dimension.ruleGroup);

                        if ("rules" in updatedGroup) {
                            updatedGroup.rules.push(getDefaultSegmentRule());
                        }
                    }
                }
                return dimension;
            });
        },
        addTargetingBlockDimensionRuleGroup: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targetingBlockIndex: number;
                dimensionIndex: number;
                path: number[];
            }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = updatedTargetingBlock.dimensions.map((dimension, i) => {
                if (i === action.payload.dimensionIndex) {
                    // Duplicate logic with next block, but TS doesn't like the push
                    if (dimension.type === TargetingDimensionTypes.CustomRules) {
                        const updatedGroup = action.payload.path.reduce((acc, idx) => {
                            return acc.rules[idx];
                        }, dimension.ruleGroup);

                        if ("rules" in updatedGroup) {
                            updatedGroup.rules.push(getDefaultCustomRuleGroup());
                        }
                    }
                    if (dimension.type === TargetingDimensionTypes.SegmentRules) {
                        const updatedGroup = action.payload.path.reduce((acc, idx) => {
                            return acc.rules[idx];
                        }, dimension.ruleGroup);

                        if ("rules" in updatedGroup) {
                            updatedGroup.rules.push(getDefaultSegmentRuleGroup());
                        }
                    }
                    return dimension;
                }
                return dimension;
            });
        },
        copyTargetingBlock: (state, action: PayloadAction<{ formKey: TargetingFormKeys; index: number }>) => {
            const blockToCopy = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[action.payload.index];
            const isStub = "include" in blockToCopy && blockToCopy.include === null;

            if (isStub) {
                return;
            }

            const isReusable = "include" in blockToCopy && blockToCopy.include !== null;

            state.targetingFormsByKey[action.payload.formKey].targetingBlocks = [
                ...state.targetingFormsByKey[action.payload.formKey].targetingBlocks,
                isReusable ? toTargetingBlock(blockToCopy) : { ...blockToCopy },
            ];
        },
        copyTargetingBlockDimension: (
            state,
            action: PayloadAction<{ formKey: TargetingFormKeys; targetingBlockIndex: number; dimensionIndex: number }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            const dimensionToClone = updatedTargetingBlock.dimensions[action.payload.dimensionIndex];

            updatedTargetingBlock.dimensions = [
                ...updatedTargetingBlock.dimensions.slice(0, action.payload.dimensionIndex + 1),
                {
                    ...dimensionToClone,
                    condition:
                        dimensionToClone.condition === Conditions.Includes ? Conditions.Excludes : Conditions.Includes,
                },
                ...updatedTargetingBlock.dimensions.slice(action.payload.dimensionIndex + 1),
            ];

            updatedTargetingBlock.usedDimensions = getUsedDimensions(updatedTargetingBlock.dimensions);
        },
        deleteTargetingBlock: (state, action: PayloadAction<{ formKey: TargetingFormKeys; index: number }>) => {
            state.targetingFormsByKey[action.payload.formKey].targetingBlocks = state.targetingFormsByKey[
                action.payload.formKey
            ].targetingBlocks.filter((_, i) => i !== action.payload.index);
        },
        deleteTargetingBlockDimension: (
            state,
            action: PayloadAction<{ formKey: TargetingFormKeys; targetingBlockIndex: number; dimensionIndex: number }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = updatedTargetingBlock.dimensions.filter(
                (_, i) => i !== action.payload.dimensionIndex
            );

            updatedTargetingBlock.usedDimensions = getUsedDimensions(updatedTargetingBlock.dimensions);
        },
        deleteTargetingBlockDimensionRule: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targetingBlockIndex: number;
                dimensionIndex: number;
                path: number[];
            }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = updatedTargetingBlock.dimensions.map((dimension, i) => {
                if (i === action.payload.dimensionIndex) {
                    // Duplicate logic with next block, but TS doesn't like default group assignment
                    if (dimension.type === TargetingDimensionTypes.CustomRules) {
                        // Rather than deleting the root group we reset it to the default
                        if (!action.payload.path.length) {
                            dimension.ruleGroup = getDefaultCustomRuleGroup();
                            return dimension;
                        }
                        const parentPath = action.payload.path.slice(0, action.payload.path.length - 1);
                        const removedIdx = action.payload.path[action.payload.path.length - 1];

                        const updatedGroup = parentPath.reduce((acc, idx) => {
                            return acc.rules[idx];
                        }, dimension.ruleGroup);

                        if ("rules" in updatedGroup) {
                            updatedGroup.rules = updatedGroup.rules.filter((_, i) => i !== removedIdx);
                        }
                    }
                    if (dimension.type === TargetingDimensionTypes.SegmentRules) {
                        // Rather than deleting the root group we reset it to the default
                        if (!action.payload.path.length) {
                            dimension.ruleGroup = getDefaultSegmentRuleGroup();
                            return dimension;
                        }
                        const parentPath = action.payload.path.slice(0, action.payload.path.length - 1);
                        const removedIdx = action.payload.path[action.payload.path.length - 1];

                        const updatedGroup = parentPath.reduce((acc, idx) => {
                            return acc.rules[idx];
                        }, dimension.ruleGroup);

                        if ("rules" in updatedGroup) {
                            updatedGroup.rules = updatedGroup.rules.filter((_, i) => i !== removedIdx);
                        }

                        updateDimensionValuesFromSegmentRuleGroup(dimension);
                    }
                    return dimension;
                }
                return dimension;
            });
        },
        loadTargeting: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targeting: Targeting[];
                targetingMode: TargetingMode;
            }>
        ) => {
            state.targetingFormsByKey[action.payload.formKey].targetingBlocks =
                action.payload.targeting.map(targetingToTargetingBlock);

            state.targetingFormsByKey[action.payload.formKey].targetingOperation =
                targetingModePayloadToTargetingOperation(action.payload.targetingMode);
        },
        loadValidationTargeting: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                validationTargeting: (Targeting | TargetingStub | TargetingBlock)[];
            }>
        ) => {
            state.targetingFormsByKey[action.payload.formKey].validationTargetingBlocks =
                action.payload.validationTargeting.map(targetingToTargetingBlock);
        },
        setTargetingBlockDimensionCondition: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targetingBlockIndex: number;
                dimensionIndex: number;
                condition: Conditions;
            }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = updatedTargetingBlock.dimensions.map((dimension, i) => {
                if (i === action.payload.dimensionIndex) {
                    return {
                        ...dimension,
                        condition: action.payload.condition,
                    };
                }
                return dimension;
            });

            updatedTargetingBlock.usedDimensions = getUsedDimensions(updatedTargetingBlock.dimensions);
        },
        setTargetingBlockDimensionMode: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targetingBlockIndex: number;
                dimensionIndex: number;
                mode: SegmentRuleModes;
            }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = updatedTargetingBlock.dimensions.map((dimension, i) => {
                if (i === action.payload.dimensionIndex) {
                    if (dimension.type === TargetingDimensionTypes.SegmentRules) {
                        return {
                            ...dimension,
                            mode: action.payload.mode,
                            values: [],
                            ruleGroup: getDefaultSegmentRuleGroup(),
                        };
                    }
                    return {
                        ...dimension,
                        mode: action.payload.mode,
                    };
                }
                return dimension;
            });
        },
        setTargetingBlockDimensionOperator: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targetingBlockIndex: number;
                dimensionIndex: number;
                path: number[];
                operator: SegmentRuleIdentifierOperators;
            }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = updatedTargetingBlock.dimensions.map((dimension, i) => {
                if (i === action.payload.dimensionIndex) {
                    if (dimension.type === TargetingDimensionTypes.SegmentRules) {
                        const updatedRule = action.payload.path.reduce((acc, idx) => {
                            return acc.rules[idx];
                        }, dimension.ruleGroup);

                        if ("operator" in updatedRule) {
                            updatedRule.operator = action.payload.operator;
                        }
                    }
                    return dimension;
                }
                return dimension;
            });
        },
        setTargetingBlockDimensionTimeZoneMode: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targetingBlockIndex: number;
                dimensionIndex: number;
                timeZoneMode: TimeZoneModeIds;
            }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = updatedTargetingBlock.dimensions.map((dimension, i) => {
                if (i === action.payload.dimensionIndex) {
                    return {
                        ...dimension,
                        timeZoneMode: action.payload.timeZoneMode,
                    };
                }
                return dimension;
            });
        },
        setTargetingBlockDimensionPmpCondition: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targetingBlockIndex: number;
                dimensionIndex: number;
                pmpCondition: PmpConditions;
            }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = updatedTargetingBlock.dimensions.map((dimension, i) => {
                if (i === action.payload.dimensionIndex) {
                    return {
                        ...dimension,
                        pmpCondition: action.payload.pmpCondition,
                    };
                }
                return dimension;
            });
        },
        setTargetingBlockDimensionRuleCondition: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targetingBlockIndex: number;
                dimensionIndex: number;
                path: number[];
                condition: GroupConditions;
            }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = updatedTargetingBlock.dimensions.map((dimension, i) => {
                if (i === action.payload.dimensionIndex) {
                    if (
                        dimension.type === TargetingDimensionTypes.CustomRules ||
                        dimension.type === TargetingDimensionTypes.SegmentRules
                    ) {
                        const updatedGroup = action.payload.path.reduce((acc, idx) => {
                            return acc.rules[idx];
                        }, dimension.ruleGroup);

                        if ("condition" in updatedGroup) {
                            updatedGroup.condition = action.payload.condition;
                        }
                    }
                    return dimension;
                }
                return dimension;
            });
        },
        setTargetingBlockDimensionRuleGroup: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targetingBlockIndex: number;
                dimensionIndex: number;
                ruleGroup: CustomRuleGroup | SegmentRuleGroup;
            }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = updatedTargetingBlock.dimensions.map((dimension, i) => {
                if (i === action.payload.dimensionIndex) {
                    if (
                        dimension.type === TargetingDimensionTypes.CustomRules ||
                        dimension.type === TargetingDimensionTypes.SegmentRules
                    ) {
                        dimension.ruleGroup = action.payload.ruleGroup;

                        if (dimension.type === TargetingDimensionTypes.SegmentRules) {
                            updateDimensionValuesFromSegmentRuleGroup(dimension);
                        }
                    }
                    return dimension;
                }
                return dimension;
            });
        },
        setTargetingBlockDimensionRuleValue: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targetingBlockIndex: number;
                dimensionIndex: number;
                path: number[];
                value: LabeledValue | string | string[] | number | number[] | null;
            }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = updatedTargetingBlock.dimensions.map((dimension, i) => {
                if (i === action.payload.dimensionIndex) {
                    if (
                        dimension.type === TargetingDimensionTypes.CustomRules ||
                        dimension.type === TargetingDimensionTypes.SegmentRules
                    ) {
                        const updatedRule = action.payload.path.reduce((acc, idx) => {
                            return acc.rules[idx];
                        }, dimension.ruleGroup);

                        if ("value" in updatedRule) {
                            updatedRule.value = action.payload.value;
                        }
                    }

                    if (dimension.type === TargetingDimensionTypes.SegmentRules) {
                        updateDimensionValuesFromSegmentRuleGroup(dimension);
                    }
                }
                return dimension;
            });
        },
        setTargetingBlockDimensionRuleId: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targetingBlockIndex: number;
                dimensionIndex: number;
                path: number[];
                id: CustomRuleIds;
            }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = updatedTargetingBlock.dimensions.map((dimension, i) => {
                if (i === action.payload.dimensionIndex) {
                    if (dimension.type === TargetingDimensionTypes.CustomRules) {
                        const updatedRule = action.payload.path.reduce((acc, idx) => {
                            return acc.rules[idx];
                        }, dimension.ruleGroup);

                        if ("id" in updatedRule) {
                            updatedRule.id = action.payload.id;
                            updatedRule.field = CUSTOM_RULE_ID_TO_FIELD[action.payload.id];
                            updatedRule.input = CUSTOM_RULE_ID_TO_INPUT[action.payload.id];
                            updatedRule.operator = CustomRuleOperators.Equal;
                            updatedRule.type = CUSTOM_RULE_ID_TO_TYPE[action.payload.id];
                            updatedRule.value = null;
                        }
                    }
                }
                return dimension;
            });
        },
        setTargetingBlockDimensionRuleOperator: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targetingBlockIndex: number;
                dimensionIndex: number;
                path: number[];
                operator: CustomRuleOperators;
            }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = updatedTargetingBlock.dimensions.map((dimension, i) => {
                if (i === action.payload.dimensionIndex) {
                    if (dimension.type === TargetingDimensionTypes.CustomRules) {
                        const updatedRule = action.payload.path.reduce((acc, idx) => {
                            return acc.rules[idx];
                        }, dimension.ruleGroup);

                        if ("operator" in updatedRule) {
                            updatedRule.operator = action.payload.operator;
                        }
                    }
                }
                return dimension;
            });
        },
        setTargetingBlockDimensionValues: (
            state,
            action: PayloadAction<{
                formKey: TargetingFormKeys;
                targetingBlockIndex: number;
                dimensionIndex: number;
                values: LabeledValue[];
            }>
        ) => {
            const updatedTargetingBlock = state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                action.payload.targetingBlockIndex
            ] as TargetingBlock;

            updatedTargetingBlock.dimensions = updatedTargetingBlock.dimensions.map((dimension, i) => {
                if (i === action.payload.dimensionIndex) {
                    return {
                        ...dimension,
                        values: action.payload.values,
                    } as TargetingDimension;
                }
                return dimension;
            });

            updatedTargetingBlock.usedDimensions = getUsedDimensions(updatedTargetingBlock.dimensions);
        },
        setTargetingOperation: (
            state,
            action: PayloadAction<{ formKey: TargetingFormKeys; operation: Operations }>
        ) => {
            state.targetingFormsByKey[action.payload.formKey].targetingOperation = action.payload.operation;
        },
        setTargetingBlockIsReusable: (
            state,
            action: PayloadAction<{ formKey: TargetingFormKeys; index: number; isReusable: boolean }>
        ) => {
            (
                state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                    action.payload.index
                ] as TargetingBlock
            ).isReusable = action.payload.isReusable;

            // Also open the reusable targeting modal if the box becomes checked
            if (action.payload.isReusable)
                (
                    state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                        action.payload.index
                    ] as TargetingBlock
                ).isReusableModalOpen = action.payload.isReusable;
        },
        setTargetingBlockIsReusableModalOpen: (
            state,
            action: PayloadAction<{ formKey: TargetingFormKeys; index: number; isReusableModalOpen: boolean }>
        ) => {
            (
                state.targetingFormsByKey[action.payload.formKey].targetingBlocks[
                    action.payload.index
                ] as TargetingBlock
            ).isReusableModalOpen = action.payload.isReusableModalOpen;
        },
        setTargetingBlockName: (
            state,
            action: PayloadAction<{ formKey: TargetingFormKeys; index: number; name: string }>
        ) => {
            state.targetingFormsByKey[action.payload.formKey][action.payload.index].name = action.payload.name;
        },
        resetTargetingBlocksByFormKey: (state, action: PayloadAction<{ formKey: TargetingFormKeys }>) => {
            state.targetingFormsByKey[action.payload.formKey] =
                initialState.targetingFormsByKey[action.payload.formKey];
        },
    },
});

export const {
    addExistingTargetingBlock,
    addTargetingBlock,
    addTargetingBlockDimension,
    addTargetingBlockDimensionRule,
    addTargetingBlockDimensionRuleGroup,
    copyTargetingBlock,
    copyTargetingBlockDimension,
    deleteTargetingBlock,
    deleteTargetingBlockDimension,
    deleteTargetingBlockDimensionRule,
    loadTargeting,
    loadValidationTargeting,
    replaceTargetingBlock,
    resetTargetingBlocksByFormKey,
    setTargetingBlockDimensionCondition,
    setTargetingBlockDimensionMode,
    setTargetingBlockDimensionOperator,
    setTargetingBlockDimensionPmpCondition,
    setTargetingBlockDimensionRuleCondition,
    setTargetingBlockDimensionRuleGroup,
    setTargetingBlockDimensionRuleId,
    setTargetingBlockDimensionRuleOperator,
    setTargetingBlockDimensionRuleValue,
    setTargetingBlockDimensionTimeZoneMode,
    setTargetingBlockDimensionValues,
    setTargetingBlockIsReusable,
    setTargetingBlockIsReusableModalOpen,
    setTargetingBlockName,
    setTargetingBlocks,
    setTargetingOperation,
} = targetingSlice.actions;

export default targetingSlice.reducer;
