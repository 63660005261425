import { ALL_ID } from "@app/core/components/Fields/ExtendedUserId/Fields";
import {
    ExtendedUserIdOptionType,
    parseOptions,
} from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields/AdSourceExtendeUserIdListField/useAdSourceExtendedUserIdList";
import { DealAdSourceTransparencyExtendedUserId } from "@app/features/deals/DealForm/types";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { Form } from "antd";
import { LabeledValue } from "antd/es/select";
import { useWatch } from "antd/lib/form/Form";
import { useMemo, useState } from "react";
import { SourceOptions } from "./Fields";

interface UseExtendedUserIdList {
    isVisible: boolean;
    sourceValue: SourceOptions | null;
    networkValue: LabeledValue | null;
    options: ExtendedUserIdOptionType[];
    handleChangeSource: (value: SourceOptions | null) => void;
    handleChangeNetwrok: (value: LabeledValue | null, allValues?: { id: number; name: string }[]) => void;
    handleAdd: VoidFunction;
    handleRemove: (options: ExtendedUserIdOptionType[]) => void;
}
export const useExtendedUserIdList = (): UseExtendedUserIdList => {
    const { setFieldValue, validateFields } = Form.useFormInstance<AdSourcesForm>();
    const [sourceValue, setSourceValue] = useState<SourceOptions | null>(null);
    const [networkValue, setNetworkValue] = useState<LabeledValue | null>(null);
    const [allNetworks, setAllNetworks] = useState<{ id: number; name: string }[]>([]);
    const includedExtendedIds = useWatch<AdSourcesForm["allowAllExtendedId"]>(
        AD_SOURCE_FIELDS.INCLUDED_EXTENDED_IDS.name
    );
    const values = useWatch<DealAdSourceTransparencyExtendedUserId[]>(AD_SOURCE_FIELDS.EXTENDED_USER_ID.name);

    const isVisible = includedExtendedIds === "specific";
    const options: ExtendedUserIdOptionType[] = useMemo(() => parseOptions(values || []), [values]);

    const handleTriggerValidation = (): Promise<unknown> => {
        return validateFields([AD_SOURCE_FIELDS.EXTENDED_USER_ID.name]);
    };

    const handleChangeSource = (value: SourceOptions | null) => {
        handleTriggerValidation();
        setSourceValue(value);

        if (!value) {
            setNetworkValue(null);
        }
    };
    const handleChangeNetwrok = (value: LabeledValue | null, allNetworks?: { id: number; name: string }[]) => {
        handleTriggerValidation();
        setNetworkValue(value);

        if (allNetworks) {
            setAllNetworks(allNetworks);
        }
    };

    const handleAdd = async () => {
        if (!sourceValue || !networkValue) return;
        try {
            await handleTriggerValidation();

            const newValue = {
                extendedId: {
                    id: sourceValue.value as number,
                    name: sourceValue.label as string,
                    code: sourceValue.code,
                },
                network: {
                    id: networkValue.value as number,
                    name: networkValue.label as string,
                },
            };

            const payload: DealAdSourceTransparencyExtendedUserId[] = [...values];

            if (networkValue.value === ALL_ID && allNetworks.length) {
                allNetworks.forEach(({ id, name }) => {
                    if (
                        !values.some(
                            ({ extendedId, network }: DealAdSourceTransparencyExtendedUserId) =>
                                extendedId.id === sourceValue.value && network.id === id
                        )
                    ) {
                        const item: DealAdSourceTransparencyExtendedUserId = {
                            extendedId: newValue.extendedId,
                            network: {
                                id,
                                name,
                            },
                        };
                        payload.push(item);
                    }
                });
            } else {
                payload.push(newValue);
            }
            setFieldValue(AD_SOURCE_FIELDS.EXTENDED_USER_ID.name, payload);

            //INFO: reset values to disable add button
            handleChangeSource(null);
            handleChangeNetwrok(null);
            setAllNetworks([]);
        } catch (error) {}
    };

    const handleRemove = (options: ExtendedUserIdOptionType[]) => {
        handleTriggerValidation();

        const newExtendedUserIds: DealAdSourceTransparencyExtendedUserId[] = options.map(
            ({ sourceId, networkId, label, code }) => {
                const [sourceName, networkName] = (label as string).split(" - ");
                return {
                    extendedId: {
                        id: sourceId,
                        name: sourceName,
                        code,
                    },
                    network: {
                        id: networkId,
                        name: networkName,
                    },
                };
            }
        );
        setFieldValue(AD_SOURCE_FIELDS.EXTENDED_USER_ID.name, newExtendedUserIds);
    };
    return {
        isVisible,
        sourceValue,
        networkValue,
        options,
        handleChangeSource,
        handleChangeNetwrok,
        handleAdd,
        handleRemove,
    };
};
