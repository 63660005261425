import { SnapshotsEntityTypes } from "@app/core/services/adStats/statsSnapshots";
import { EntityTypes } from "@app/features/adStats";
import { css } from "@emotion/css";
import { Card, Collapse, Form } from "antd";
import { FC } from "react";
import {
    AD_UNIT_DETAILS_SECTIONS_NAME,
    CHANNEL_DETAILS_SECTIONS_NAME,
    classNameCardBody,
    PLATFORM_ID,
    PLAYLIST_ID,
    POD_ID,
    SECTIONS_NAMES,
} from "../constants";
import { HelpKeysButton } from "../HelpKeysButton";
import { BrandSafety, Labels, Performance, Pixels } from "../SharedSections";
import { Defaults, General, DetailedConfiguration } from "./Sections";
import { FloorsTable } from "@app/features/inventory/components/FloorsTable";
import { Loading, SectionTitle } from "@app/core/components";
import { BrandChannel, useGetSeatSelfQuery } from "@app/core/services";
import { AdBreakRules, AdPod, PlaylistConfiguration } from "../AdUnitSections/sections";
import { useUserAccess } from "@app/core/auth";
import { useAppSelector } from "@app/core/store";
import { InventoryDetailsDrawerType, selectInventoryDetailsDrawerType } from "../../DetailsDrawer/reducer";
import { useParams } from "react-router";
import { ExternalCodesTable } from "@app/features/inventory/components/ExternalCodesTable";

const parentCollpaseClassName = css`
    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }
`;

const innerCollapseClassName = css`
    .ant-collapse-content > .ant-collapse-content-box {
        padding-left: 18px;
        padding-right: 18px;
    }

    .ant-collapse-header-text {
        font-weight: 500;
    }
`;

interface Props {
    channel: BrandChannel;
}

export const ChannelSections: FC<Props> = ({ channel }) => {
    const distributionGroupLabelValues = channel?.brand?.distributionGroupLabelValues || [];
    const { seatId } = useParams<{ seatId: string }>();
    const { data: seatSelfData } = useGetSeatSelfQuery(seatId);
    const seatData = seatSelfData?.seat || null;
    const { isSysAdmin, isPubAcctMgr, isTremorUser } = useUserAccess();
    const isInternalOnly: boolean = isSysAdmin || isPubAcctMgr;
    const drawerType = useAppSelector(selectInventoryDetailsDrawerType);
    const isPublisherChannel = drawerType === InventoryDetailsDrawerType.CHANNEL;
    const isPubBrandSafety = !!channel?.publisher.buyerSeatList;

    if (!channel) {
        return <Loading />;
    }

    return (
        <Form layout="vertical" style={{ width: "100%" }} data-sdet="channel-sections">
            <Collapse defaultActiveKey={[1, 2, 3, 4, 5, 6, 7, 8, 9]} bordered ghost={false} collapsible="header">
                <Collapse.Panel
                    key={1}
                    header={<SectionTitle title={CHANNEL_DETAILS_SECTIONS_NAME().performance.label} />}
                    extra={
                        <HelpKeysButton
                            label={CHANNEL_DETAILS_SECTIONS_NAME().performance.label}
                            sectionKey={SECTIONS_NAMES.PERFORMANCE}
                        />
                    }
                >
                    <Performance
                        id={channel?.brand?.id}
                        adStatsEntity={EntityTypes.Brand}
                        snapShotEntity={SnapshotsEntityTypes.Brand}
                    />
                </Collapse.Panel>
                <Collapse.Panel
                    key={2}
                    header={<SectionTitle title={CHANNEL_DETAILS_SECTIONS_NAME().general.label} />}
                    extra={
                        <HelpKeysButton
                            label={CHANNEL_DETAILS_SECTIONS_NAME(isInternalOnly).general.label}
                            sectionKey={SECTIONS_NAMES.GENERAL}
                        />
                    }
                >
                    <Card bordered={false} className={classNameCardBody}>
                        <General
                            isInternalOnly={isInternalOnly}
                            isPublisherChannel={isPublisherChannel}
                            name={channel?.name}
                            description={
                                isPublisherChannel ? channel?.publisher?.description : channel?.brand?.description
                            }
                            status={channel?.adUnit?.status?.name}
                            publisherCode={channel?.publisher?.code}
                            brandCode={channel?.brand?.code}
                            adUnitCode={channel?.adUnit?.code}
                            billingCode={channel?.publisher?.billingCode}
                            accountType={channel?.publisher?.accountType}
                        />
                    </Card>
                </Collapse.Panel>

                <Collapse.Panel
                    key={3}
                    header={<SectionTitle title={CHANNEL_DETAILS_SECTIONS_NAME().advancedFeatures.label} />}
                    className={parentCollpaseClassName}
                >
                    <Collapse
                        defaultActiveKey={[1, 2, 3, 4, 5, 6, 7]}
                        bordered={false}
                        style={{ background: "transparent" }}
                    >
                        <Collapse.Panel
                            key={1}
                            header={<SectionTitle title={CHANNEL_DETAILS_SECTIONS_NAME().defaults.label} />}
                            extra={
                                <HelpKeysButton
                                    label={CHANNEL_DETAILS_SECTIONS_NAME().defaults.label}
                                    sectionKey={SECTIONS_NAMES.DEFAULTS}
                                />
                            }
                            className={innerCollapseClassName}
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <Defaults
                                    adUnit={channel?.adUnit}
                                    hasPrivacyPolicy={channel?.supply?.hasPrivacyPolicy}
                                    iabCategories={channel?.brand?.iabCategories}
                                    inventoryPartnerDomain={channel?.supply?.inventoryPartnerDomain}
                                    isCoppa={channel?.supply?.isCoppa}
                                />
                            </Card>
                        </Collapse.Panel>
                        {channel?.adUnit?.multiplicity?.id === PLAYLIST_ID && (
                            <Collapse.Panel
                                key={2}
                                header={
                                    <SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().playlistConfiguration.label} />
                                }
                                extra={
                                    <HelpKeysButton
                                        label={AD_UNIT_DETAILS_SECTIONS_NAME().playlistConfiguration.label}
                                        sectionKey={SECTIONS_NAMES.PLAYLIST_CONFIGURATION}
                                    />
                                }
                            >
                                <Card bordered={false} className={classNameCardBody}>
                                    <PlaylistConfiguration adUnit={channel.adUnit} />
                                </Card>
                            </Collapse.Panel>
                        )}
                        {channel?.adUnit?.multiplicity?.id === POD_ID && (
                            <Collapse.Panel
                                key={3}
                                header={<SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().adPod.label} />}
                                extra={
                                    <HelpKeysButton
                                        label={AD_UNIT_DETAILS_SECTIONS_NAME().adPod.label}
                                        sectionKey={SECTIONS_NAMES.AD_POD}
                                    />
                                }
                                className={innerCollapseClassName}
                            >
                                <Card bordered={false} className={classNameCardBody}>
                                    <AdPod adUnit={channel.adUnit} />
                                </Card>
                            </Collapse.Panel>
                        )}
                        {channel.adUnit?.playlistDefinitionMode?.id === PLATFORM_ID &&
                            channel.adUnit?.adBreakRules?.length && (
                                <Collapse.Panel
                                    key={4}
                                    className={innerCollapseClassName}
                                    header={<SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().adBreakRules.label} />}
                                >
                                    <Card bordered={false} className={classNameCardBody}>
                                        <AdBreakRules adUnit={channel.adUnit} />
                                    </Card>
                                </Collapse.Panel>
                            )}
                        <Collapse.Panel
                            key={5}
                            header={<SectionTitle title={CHANNEL_DETAILS_SECTIONS_NAME().brandSafety.label} />}
                            extra={
                                <HelpKeysButton
                                    label={CHANNEL_DETAILS_SECTIONS_NAME().brandSafety.label}
                                    sectionKey={SECTIONS_NAMES.BRAND_SAFETY}
                                />
                            }
                            className={innerCollapseClassName}
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <BrandSafety
                                    unit={
                                        isPubBrandSafety
                                            ? {
                                                  ...channel?.publisher,
                                                  blockedIabCategories: channel?.brand.blockedIabCategories,
                                              }
                                            : channel?.brand
                                    }
                                    hasBlockedCategories
                                    hasBlockedIndustries
                                />
                            </Card>
                        </Collapse.Panel>
                        {seatData?.externalAdUnitCodeNamespaces?.length && (
                            <Collapse.Panel
                                key={6}
                                header={<SectionTitle title={CHANNEL_DETAILS_SECTIONS_NAME().sspConnect.label} />}
                            >
                                <Card bordered={false} className={classNameCardBody}>
                                    <ExternalCodesTable
                                        style={{ marginBottom: "20px" }}
                                        externalCodes={channel?.adUnit?.externalCodes}
                                    />
                                </Card>
                            </Collapse.Panel>
                        )}
                        <Collapse.Panel
                            key={7}
                            header={
                                <SectionTitle title={CHANNEL_DETAILS_SECTIONS_NAME().detailedConfiguration.label} />
                            }
                            extra={
                                <HelpKeysButton
                                    label={CHANNEL_DETAILS_SECTIONS_NAME().detailedConfiguration.label}
                                    sectionKey={SECTIONS_NAMES.DETAILED_CONFIGURATION}
                                />
                            }
                            className={innerCollapseClassName}
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <DetailedConfiguration
                                    isTremorUser={isTremorUser}
                                    isInternalOnly={isInternalOnly}
                                    businessDomain={channel?.publisher?.businessDomain}
                                    internalLabelValues={channel?.publisher?.internalLabelValues}
                                    thirdPartyPixles={channel?.publisher?.thirdPartyPixels}
                                    enableAdvertisersAsWhitelist={channel?.brand?.enableAdvertisersAsWhitelist}
                                    enableNoAdCookieSync={channel?.supply?.enableNoAdCookieSync}
                                    allowVastWrappers={channel?.supply?.allowVastWrappers}
                                    impressionWaitTime={channel?.adUnit?.impressionWaitTime?.name}
                                    unwrapVast={channel?.adUnit?.unwrapVast}
                                    adomainFreqCappings={channel?.adUnit?.adomainFreqCappings}
                                    publisherReauction={channel?.adUnit?.publisherReauction}
                                />
                            </Card>
                        </Collapse.Panel>
                    </Collapse>
                </Collapse.Panel>
                <Collapse.Panel
                    key={4}
                    header={<SectionTitle title={CHANNEL_DETAILS_SECTIONS_NAME().pixels.label} />}
                    extra={
                        <HelpKeysButton
                            label={CHANNEL_DETAILS_SECTIONS_NAME(isInternalOnly).pixels.label}
                            sectionKey={SECTIONS_NAMES.PIXELS}
                        />
                    }
                >
                    <Card bordered={false} className={classNameCardBody}>
                        <Pixels thirdPartyPixels={channel?.publisher?.thirdPartyPixels} id={-1} />
                    </Card>
                </Collapse.Panel>
                <Collapse.Panel
                    key={5}
                    header={<SectionTitle title={CHANNEL_DETAILS_SECTIONS_NAME().labels.label} />}
                    extra={
                        <HelpKeysButton
                            label={CHANNEL_DETAILS_SECTIONS_NAME(isInternalOnly).labels.label}
                            sectionKey={SECTIONS_NAMES.LABELS}
                        />
                    }
                >
                    <Card bordered={false} className={classNameCardBody}>
                        <Labels
                            distributionGroupsEnabled={seatData?.distributionGroupsEnabled && !isPublisherChannel}
                            distributionGroupLabelValues={distributionGroupLabelValues}
                            labels={channel?.publisher?.labelValues}
                            internalLabelValues={channel?.publisher?.internalLabelValues}
                            isInternalOnly={isInternalOnly}
                        />
                    </Card>
                </Collapse.Panel>

                <Collapse.Panel key={6} header={<SectionTitle title={CHANNEL_DETAILS_SECTIONS_NAME().floors.label} />}>
                    <Card bordered={false} className={classNameCardBody}>
                        <FloorsTable
                            readOnly
                            id={channel?.id}
                            hiddenColumns={["actualPrice"]}
                            sourceSelf={isPublisherChannel ? "Publisher" : "Brand"}
                        />
                    </Card>
                </Collapse.Panel>
            </Collapse>
        </Form>
    );
};
