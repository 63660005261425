import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { TransparencyFormItem } from "../TransparencyFormItem";
import { useAdSourceExtendedUserIdList } from "./useAdSourceExtendedUserIdList";
import { ExtendedUserId } from "@app/core/components/Fields/ExtendedUserId";

export const AdSourceExtendedUserIdList = () => {
    const {
        isVisible,
        options,
        sourceValue,
        handleChangeSource,
        networkValue,
        handleChangeNetwrok,
        handleAdd,
        handleRemove,
    } = useAdSourceExtendedUserIdList();
    if (!isVisible) {
        return null;
    }
    return (
        <TransparencyFormItem
            label={AD_SOURCE_FIELDS.EXTENDED_USER_ID.label}
            name={AD_SOURCE_FIELDS.EXTENDED_USER_ID.name}
            rules={[
                {
                    validator() {
                        if (
                            options.some(
                                ({ sourceId, networkId }) =>
                                    sourceId === sourceValue?.value && networkId === networkValue?.value
                            )
                        ) {
                            return Promise.reject("This combination already exists");
                        }
                        return Promise.resolve();
                    },
                },
            ]}
        >
            <ExtendedUserId
                selectedOptions={options}
                sourceValue={sourceValue}
                networkValue={networkValue}
                handleChangeSource={handleChangeSource}
                handleChangeNetwrok={handleChangeNetwrok}
                handleAdd={handleAdd}
                handleRemove={handleRemove}
            />
        </TransparencyFormItem>
    );
};
