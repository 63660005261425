import { ALL_ID } from "@app/core/components/Fields/ExtendedUserId/Fields";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME, TRANSPARENCY_SPECIFIC_VALUE } from "@app/features/deals/constants";
import { dealFormAdSourceExtendedUserId } from "@app/features/deals/DealForm/reducer";
import { DealAdSourceTransparencyExtendedUserId } from "@app/features/deals/DealForm/types";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { Form } from "antd";
import { LabeledValue } from "antd/es/select";
import { useMemo, useState } from "react";
import { SourceOptions } from "./Fields";

export const parseOptions = (values: DealAdSourceTransparencyExtendedUserId[]): ExtendedUserIdOptionType[] => {
    //INFO: network properties could be null due to old ui and api
    return values.map(({ extendedId, network }: DealAdSourceTransparencyExtendedUserId) => ({
        label: network?.id ? `${extendedId.name} - ${network.name}` : `${extendedId.name}`,
        value: network?.id ? `${extendedId.id} - ${network.id}` : `${extendedId.id}`,
        sourceId: extendedId.id,
        networkId: network?.id,
        code: extendedId.code,
    }));
};

export interface ExtendedUserIdOptionType extends LabeledValue {
    sourceId: number;
    networkId: number;
    code: string;
}

interface UseAdSourceExtendedIdList {
    isVisible: boolean;
    options: ExtendedUserIdOptionType[];
    sourceValue: SourceOptions | null;
    networkValue: LabeledValue | null;
    handleChangeSource: (value: SourceOptions | null) => void;
    handleChangeNetwrok: (value: LabeledValue | null, allValues?: { id: number; name: string }[]) => void;
    handleAdd: VoidFunction;
    handleRemove: (options: ExtendedUserIdOptionType[]) => void;
}
export const useAdSourceExtendedUserIdList = (): UseAdSourceExtendedIdList => {
    const formInstance = Form.useFormInstance();
    const includedExtendedIds = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_ALL_EXTENDED_ID]
    );
    const values = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOWED_EXTENDED_ID_TRANSPARENCIES]
    );
    const isVisible = includedExtendedIds === TRANSPARENCY_SPECIFIC_VALUE;
    const [sourceValue, setSourceValue] = useState<SourceOptions | null>(null);
    const [networkValue, setNetworkValue] = useState<LabeledValue | null>(null);
    const [allNetworks, setAllNetworks] = useState<{ id: number; name: string }[]>([]);

    const options: ExtendedUserIdOptionType[] = useMemo(() => parseOptions(values), [values]);

    const dispatch = useAppDispatch();

    const handleTriggerValidation = (): Promise<unknown> => {
        return formInstance.validateFields([AD_SOURCE_FIELDS.EXTENDED_USER_ID.name]);
    };

    const handleChangeSource = (value: SourceOptions | null) => {
        handleTriggerValidation();
        setSourceValue(value);

        if (!value) {
            setNetworkValue(null);
        }
    };
    const handleChangeNetwrok = (value: LabeledValue | null, allNetworks?: { id: number; name: string }[]) => {
        handleTriggerValidation();
        setNetworkValue(value);

        if (allNetworks) {
            setAllNetworks(allNetworks);
        }
    };

    const handleAdd = async () => {
        if (!sourceValue || !networkValue) return;
        if (
            values.some(
                ({ extendedId, network }: DealAdSourceTransparencyExtendedUserId) =>
                    extendedId.id === sourceValue.value && network.id === networkValue.value
            )
        ) {
            return;
        }

        const payload: DealAdSourceTransparencyExtendedUserId[] = [...values];

        const newValue = {
            extendedId: {
                id: sourceValue.value as number,
                name: sourceValue.label as string,
                code: sourceValue.code,
            },
            network: {
                id: networkValue.value as number,
                name: networkValue.label as string,
            },
        };

        if (networkValue.value === ALL_ID && allNetworks.length) {
            allNetworks.forEach(({ id, name }) => {
                if (
                    !values.some(
                        ({ extendedId, network }: DealAdSourceTransparencyExtendedUserId) =>
                            extendedId.id === sourceValue.value && network.id === id
                    )
                ) {
                    const item: DealAdSourceTransparencyExtendedUserId = {
                        extendedId: newValue.extendedId,
                        network: {
                            id,
                            name,
                        },
                    };
                    payload.push(item);
                }
            });
        } else {
            payload.push(newValue);
        }

        dispatch(dealFormAdSourceExtendedUserId(payload));

        //INFO: reset values to disable add button
        handleChangeSource(null);
        handleChangeNetwrok(null);
        setAllNetworks([]);
    };

    const handleRemove = (options: ExtendedUserIdOptionType[]) => {
        handleTriggerValidation();

        const newExtendedUserIds: DealAdSourceTransparencyExtendedUserId[] = options.map(
            ({ sourceId, networkId, label, code }) => {
                const [sourceName, networkName] = (label as string).split(" - ");
                return {
                    extendedId: {
                        id: sourceId,
                        name: sourceName,
                        code,
                    },
                    network: {
                        id: networkId,
                        name: networkName,
                    },
                };
            }
        );
        dispatch(dealFormAdSourceExtendedUserId(newExtendedUserIds));
    };

    return {
        isVisible,
        options,
        sourceValue,
        handleChangeSource,
        networkValue,
        handleChangeNetwrok,
        handleAdd,
        handleRemove,
    };
};
