import { FC } from "react";
import { Card, Collapse } from "antd";
import {
    PACING_DEFAULT_ACTIVE_KEY,
    AD_SOURCE_PACING_HELP_FIELDS,
    AD_SOURCE_LADLES_HELP_FIELDS,
    AD_SOURCE_BRAND_SAFETY_HELP_FIELDS,
    AD_SOURCE_TRANSPARENCY_HELP_FIELDS,
    AD_SOURCE_CUSTOM_PIXEL_HELP_FIELDS,
} from "@app/features/deals/constants";
import {
    cardBodyStyle,
    sectionHeaderCollapseStyle,
    secondaryStepsSectionClassList,
} from "@app/features/deals/DealForm";
import { Title } from "@app/core/components";
import { AdSource } from "@app/core/services";
import { ItemType } from "rc-collapse/es/interface";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import {
    AdSourcePacingSection,
    AdSourceLabelsSection,
    AdSourceDemandSection,
    AdSourceTargetingSection,
    AdSourceBrandSafetySection,
    AdSourceCustomPixelsSection,
    AdSourceTransparencySection,
    AdSourceAdminOptionsSection,
    AdSourceConfigurationSection,
    AdSourceFloorSettingsSection,
    AdSourceDeliveryDetailsSection,
} from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections";
import { AdSourceEnrichmentCostSection } from "./DealFormCreateAdSourceSections/AdSourceEnrichmentCostSection";
import { useDealFormCreateAdSource } from "@app/features/deals/DealForm/DealFormCreateAdSource/useDealFormCreateAdSource";
import { isPacingEligible } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";

export interface CreateAdSourceProps {
    dealAdSourceData: AdSource | undefined;
    hasMultipleDeals: boolean;
    mode: DealFormMode;
}

export const DealFormCreateAdSource: FC<CreateAdSourceProps> = ({ dealAdSourceData, hasMultipleDeals, mode }) => {
    const {
        activeSections,
        isAdSourceDailyImpressionCapEnabled,
        demandSectionRef,
        setActiveSections,
        hasFloorSettings,
        hasDomainNameOverride,
        hasBundleIdOverride,
        hasExtendedUserId,
        hasAdSourceAudienceLockEnabled,
        hasExistingAdSource,
        adSourceTotalImpressionsIsShown,
        hasCreateFields,
        hasAdminOptions,
        executeScroll,
        adSourceAdResponsePriceOverrideShown,
        isProgrammaticGuaranteed,
        adSourceTypeId,
        pacingTypeId,
        showFallbackOpportunity,
    } = useDealFormCreateAdSource();

    return (
        <>
            <Card bordered={false} styles={{ body: cardBodyStyle }} data-sdet="deal-form-create-ad-source">
                <AdSourceConfigurationSection
                    hasExistingAdSource={hasExistingAdSource}
                    hasCreateFields={hasCreateFields}
                    hasMultipleDeals={hasMultipleDeals}
                    executeScroll={executeScroll}
                    mode={mode}
                    isProgrammaticGuaranteed={isProgrammaticGuaranteed}
                />
                {hasAdminOptions && <AdSourceAdminOptionsSection />}
            </Card>
            {hasCreateFields && (
                <>
                    {hasFloorSettings && (
                        <AdSourceFloorSettingsSection
                            mode={mode}
                            adSourceAdResponsePriceOverrideShown={adSourceAdResponsePriceOverrideShown}
                        />
                    )}
                    {hasMultipleDeals && mode === "edit" && (
                        <div ref={demandSectionRef}>
                            <AdSourceDemandSection dealAdSourceData={dealAdSourceData} />
                        </div>
                    )}
                    <Collapse
                        className={secondaryStepsSectionClassList}
                        activeKey={activeSections}
                        onChange={setActiveSections}
                        collapsible="header"
                        items={
                            [
                                isPacingEligible(adSourceTypeId) && {
                                    label: <Title level={5} title="Pacing" />,
                                    key: PACING_DEFAULT_ACTIVE_KEY,
                                    extra: (
                                        <HelpKeysDrawer
                                            panelLabel="Pacing"
                                            definitionsFields={AD_SOURCE_PACING_HELP_FIELDS}
                                        />
                                    ),
                                    children: (
                                        <AdSourcePacingSection
                                            mode={mode}
                                            adSourceTypeId={adSourceTypeId}
                                            pacingTypeId={pacingTypeId}
                                            showFallbackOpportunity={showFallbackOpportunity}
                                            isAdSourceDailyImpressionCapEnabled={isAdSourceDailyImpressionCapEnabled}
                                        />
                                    ),
                                },
                                {
                                    key: "2",
                                    children: (
                                        <AdSourceDeliveryDetailsSection
                                            adSourceTotalImpressionsIsShown={adSourceTotalImpressionsIsShown}
                                            mode={mode}
                                        />
                                    ),
                                    label: <Title level={5} title="Delivery Details" />,
                                },
                                {
                                    key: "3",
                                    extra: (
                                        <HelpKeysDrawer
                                            panelLabel="Brand Safety"
                                            definitionsFields={AD_SOURCE_BRAND_SAFETY_HELP_FIELDS}
                                        />
                                    ),
                                    children: <AdSourceBrandSafetySection />,
                                    label: <Title level={5} title="Brand Safety" />,
                                },
                                {
                                    key: "4",
                                    extra: (
                                        <HelpKeysDrawer
                                            panelLabel="Custom Pixels"
                                            definitionsFields={AD_SOURCE_CUSTOM_PIXEL_HELP_FIELDS}
                                        />
                                    ),
                                    children: <AdSourceCustomPixelsSection />,
                                    label: <Title level={5} title="Custom Pixels" />,
                                },
                                {
                                    key: "5",
                                    extra: (
                                        <HelpKeysDrawer
                                            panelLabel="Transparency"
                                            definitionsFields={AD_SOURCE_TRANSPARENCY_HELP_FIELDS}
                                        />
                                    ),
                                    children: (
                                        <AdSourceTransparencySection
                                            hasBundleIdOverride={hasBundleIdOverride}
                                            hasDomainNameOverride={hasDomainNameOverride}
                                            hasExtendedUserId={hasExtendedUserId}
                                            hasAdSourceAudienceLockEnabled={hasAdSourceAudienceLockEnabled}
                                        />
                                    ),
                                    label: <Title level={5} title="Transparency" />,
                                },
                                {
                                    key: "6",
                                    extra: (
                                        <HelpKeysDrawer
                                            panelLabel="Labels"
                                            definitionsFields={AD_SOURCE_LADLES_HELP_FIELDS}
                                        />
                                    ),
                                    children: <AdSourceLabelsSection />,
                                    label: <Title level={5} title="Labels" />,
                                },
                                {
                                    key: "7",
                                    children: <AdSourceTargetingSection />,
                                    label: <Title level={5} title="Targeting" />,
                                },
                                {
                                    key: "8",
                                    style: sectionHeaderCollapseStyle,
                                    children: <AdSourceEnrichmentCostSection />,
                                    label: <Title level={5} title="Enrichment Cost" />,
                                },
                            ].filter(Boolean) as ItemType[]
                        }
                    />
                </>
            )}
        </>
    );
};
