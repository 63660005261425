import {
    AD_SOURCE_FIELDS,
    DefaultDemandAcquisitionCostModel,
    DefaultIndustriesMode,
    FlightPacingPeriod,
} from "../../constants";
import { VAST_DYNAMIC_PRICING_LOCATIONS } from "../AdSourcesSections/PricingSection/Fields";
import { format } from "@rubicon/utils";
import moment from "moment-timezone";

const { SHARED } = format.constants;

export const initialFormValues = {
    [AD_SOURCE_FIELDS.START_DATE.name]: moment(),
    [AD_SOURCE_FIELDS.TIME_ZONE.name]: {
        creationDate: "2015-03-24T23:05:54.000+0000",
        updateDate: "2015-03-24T23:05:54.000+0000",
        id: 614,
        code: "UTC",
        name: "Coordinated Universal Time",
        dayLightTime: false,
        dayLightSavingsOffset: 0,
        utcOffset: 0,
        entityType: "TimeZone",
    },
    [AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_LOCATION.name]: VAST_DYNAMIC_PRICING_LOCATIONS?.STANDARD_PRICING_ELEMENTS.id,
    [AD_SOURCE_FIELDS.PRICE_MODEL.name]: {
        creationDate: "2015-04-01T22:33:21.000+0000",
        updateDate: "2015-04-01T22:33:21.000+0000",
        id: 1,
        name: "Fixed Price",
        sort: 1,
        entityType: "AdSourcePriceModel",
    },
    [AD_SOURCE_FIELDS.CURRENCY.name]: {
        creationDate: "2016-03-01T22:13:24.000+0000",
        updateDate: "2016-03-01T22:13:24.000+0000",
        id: 1,
        code: "USD",
        sort: 1,
        entityType: "CurrencyType",
    },
    [AD_SOURCE_FIELDS.FLOOR_TYPE.name]: {
        creationDate: "2015-09-16T20:10:29.000+0000",
        updateDate: "2015-09-16T20:10:29.000+0000",
        id: 1,
        name: "Override",
        sort: 1,
        entityType: "AdSourceFloorType",
    },
    [AD_SOURCE_FIELDS.PRIORITY.name]: {
        creationDate: "2014-05-22T18:12:26.000+0000",
        updateDate: "2014-05-22T18:12:26.000+0000",
        id: 7,
        name: "7",
        sort: 7,
        entityType: "Priority",
    },
    [AD_SOURCE_FIELDS.MODE.name]: {
        creationDate: "2016-05-31T18:57:54.000+0000",
        updateDate: "2016-05-31T18:57:54.000+0000",
        id: 1,
        name: "Block (Use Inventory Blocked Domains)",
        sort: 1,
        entityType: "AdSourceAdvertiserBlockingMode",
    },
    [AD_SOURCE_FIELDS.STATUS.name]: {
        creationDate: "2014-05-22T18:12:27.000+0000",
        updateDate: "2014-05-22T18:12:27.000+0000",
        id: 1,
        name: "Running",
        sort: 1,
        entityType: "AdSourceStatus",
    },
    [AD_SOURCE_FIELDS.COST_MODEL.name]: {
        creationDate: "2014-05-22T18:12:33.000+0000",
        updateDate: "2014-05-22T18:12:33.000+0000",
        id: 1,
        name: "Revshare Percentage",
        entityType: "CostModel",
    },
    [AD_SOURCE_FIELDS.AUCTION_TYPE.name]: {
        id: 1,
        name: "1st Price Auction",
        key: 1,
        entityType: "AdSourceAuctionType",
    },
    [AD_SOURCE_FIELDS.PACING_PERIOD.name]: FlightPacingPeriod,
    [AD_SOURCE_FIELDS.PACING_REDISTRIBUTION.name]: false,
    [AD_SOURCE_FIELDS.FALLBACK_OPPORTUNITY.name]: 0,
    [AD_SOURCE_FIELDS.DAILY_IMPRESSION_CAP_ENABLED.name]: false,
    [AD_SOURCE_FIELDS.TARGET_DATE.name]: moment(),
    [AD_SOURCE_FIELDS.PIXELS.name]: [],
    [AD_SOURCE_FIELDS.SUPPLY_NAME.name]: true,
    [AD_SOURCE_FIELDS.DOMAIN_NAME.name]: true,
    [AD_SOURCE_FIELDS.ALLOW_AUDIENCE_LOCK.name]: false,
    [AD_SOURCE_FIELDS.SUPPLY_PAGE.name]: true,
    [AD_SOURCE_FIELDS.REFER_URL.name]: true,
    [AD_SOURCE_FIELDS.FULL_IP_ADDRESS.name]: true,
    [AD_SOURCE_FIELDS.BUNDLE_ID.name]: true,
    [AD_SOURCE_FIELDS.STORE_URL.name]: true,
    [AD_SOURCE_FIELDS.USER_ID.name]: true,
    [AD_SOURCE_FIELDS.BVOD_CONNECT_ID.name]: false,
    [AD_SOURCE_FIELDS.BVOD_CONNECT_DEMOGRAPHIC.name]: 0,
    [AD_SOURCE_FIELDS.CONTENT.name]: { name: SHARED, value: true },
    [AD_SOURCE_FIELDS.INCLUDED_EXTENDED_IDS.name]: true,
    [AD_SOURCE_FIELDS.WATERFALL_PRE_FILTER_MODE.name]: false,
    [AD_SOURCE_FIELDS.RUN_UNDER_INBOUND_FLOOR.name]: false,
    [AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name]: null,
    [AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST_MODEL.name]: DefaultDemandAcquisitionCostModel,
    [AD_SOURCE_FIELDS.IAB_CATEGORY_BLOCK_MODE.name]: {
        creationDate: "2023-09-26T18:43:52.000+0000",
        updateDate: "2023-09-26T18:43:52.000+0000",
        id: 1,
        name: "Block (Use Inventory Blocked Categories)",
        sort: 1,
        entityType: "IabCategoryBlockMode",
    },
    [AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.name]: [],
    [AD_SOURCE_FIELDS.AD_RESPONSE_PRICE_OVERRIDE.name]: {
        id: 1,
        name: "None",
    },
    [AD_SOURCE_FIELDS.INDUSTRIES_MODE.name]: DefaultIndustriesMode,
};
