import { Brand, BrandSupply } from "./brands";
import {
    SUPPLY,
    consoleApi,
    SUPPLY_SEAT_TAG,
    SUPPLY_TYPES_TAG,
    BRANDS_SUPPLY_TAG,
    SUPPLY_AD_UNITS_TAG,
    SUPPLY_PAID_TYPES_TAG,
    SUPPLY_DOMAIN_FILTER_LIST_ID_AND_NAME_TAG,
    SUPPLY_BUNDLE_ID_FILTER_LIST_ID_AND_NAME_TAG,
    SUPPLY_RESELL_TYPES_TAG,
    SEAT_ADS_TEXT_TAG,
    SUPPLY_VIDEO_QUALITY_TYPES_TAG,
    SUPPLY_RELATIONSHIPS_TYPES_TAG,
    SUPPLY_IFA_TYPES_TAG,
    VIEWABILITY_VENDORS_TAG,
    SUPPLY_INHERITED_PIXELS_TAG,
    SEAT_NUKE_STATUS,
    CHANGELOG_TAG,
    STRUCTURED_SEAT_NUKES,
} from "./console";
import { SeatAdvertiserDomainFilterListDef } from "./seatAdvertiserDomainFilterListDefs";
import { Label, LabelValue } from "./labels";
import { toQueryString } from "./utils";
import { ChangeLogEntityType, IabCategory, ThirdPartyPixel } from "@app/core/services";

export interface Supply {
    id: number;
    name: string;
    supplyNameOverride: null;
    brand: Brand;
    code: string;
    entityType: "Supply";
}

export interface Entity {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
}

export interface SupplyType extends Entity {
    sort: number;
    entityType: "SupplyType";
}

export interface AdomainFreqCapping {
    id?: number | string;
    total: number;
    timeDurationMin: number;
    mode: {
        id: number;
        name: string;
    };
}

export interface SupplyPaidType {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    sort: number;
    entityType: "SupplyPaidType";
}

export interface SupplyResellType {
    creationDate: string;
    entityType: "SupplyResellType";
    id: number;
    name: string;
    sort: number;
    updateDate: string;
}

export interface SupplyVideoQualityType {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    sort: number;
    entityType: "SupplyVideoQuality";
}

export interface SupplySourceRelationship {
    creationDate: string;
    entityType: "SupplySourceRelationship";
    id: number;
    name: string;
    sort: number;
    updateDate: string;
}

export interface SupplyIfaTypes {
    creationDate: string;
    entityType: "IfaType";
    id: number;
    name: string;
    sort: number;
    updateDate: string;
}

export interface ViewabilityVendor {
    creationDate: string;
    entityType: "ViewabilityVendor";
    id: number;
    name: string;
    sort: number;
    updateDate: string;
}

export interface IndustriesCategory {
    code: string;
    id: number;
    name: string;
    parentId: string;
    parentName: string;
}

export interface AdBreakRules {
    adBreakLabel: string;
    id: number;
    maxAdsPerPod: number;
    maxPodLengthSeconds: number;
    midRollIndex: number | null;
    type: Entity;
}

export interface ExternalCode {
    extAdUnitCode: string;
    extAdUnitCodeNamespace: Entity;
    extAdUnitCodeNamespaceType: Entity;
}

export interface AdUnit {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    description: string;
    maxDuration: number;
    minDuration: number;
    code: string;
    supply: BrandSupply;
    cuepoints: unknown[];
    lsaEnabled: boolean;
    lsaWindowSizeSeconds: number | null;
    type: Entity | null;
    status: Entity;
    linearity: Entity;
    adPodFillMode: Entity;
    maxAdsPerPod: number | null;
    maxPodSeconds: number | null;
    thirdPartyPixels: ThirdPartyPixel[];
    playlistDefinitionMode: Entity;
    maxAdsPerAdvertiser: number | null;
    separationGroups: {
        id: number;
        name: string;
        iabCategories: IabCategory[];
        industries: IndustriesCategory[];
    }[];
    podEnforcement: Entity;
    startDelay: number;
    multiplicity: Entity;
    allowedPlaybackMethods: Entity[];
    blockedCreativeAttributes: Entity[];
    supportedApis: Entity[];
    supportedProtocols: Entity[];
    mimes: Entity[];
    isInterstitial: boolean;
    isBoxingAllowed: boolean;
    affiliateCostModel?: { id: number; name: string };
    affiliateCostValuePercent?: number | null;
    affiliateCostValueFixed?: number | null;
    creativeSamplesMinute: number;
    ssaiType: Entity | null;
    unwrapVast: boolean;
    adomainFreqCappingType: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
    };
    minBitrate: number | null;
    maxBitrate: number | null;
    maxExtended: number;
    skip: boolean | null;
    skipmin: null | number;
    skipafter: null | number;
    impressionWaitTime: Entity;
    advertiserBlockDomains: string[];
    seatAdvertiserDomainFilterListDefs: SeatAdvertiserDomainFilterListDef[];
    labelValues: LabelValue[];
    internalLabelValues: LabelValue[];
    prefilterLabelValues: {
        id: number;
        value: string;
        label: {
            id: number;
            key: string;
        };
    }[];
    buyerSeatList: {
        id: number;
        name: string;
    } | null;
    adomainFreqCappings: AdomainFreqCapping[];
    placement: Entity;
    videoDeliveries: Entity[];
    externalCodes: ExternalCode[];
    publisherReauction: boolean | null;
    isLiveStream: boolean;
    contentChannel: string | null;
    adBreakRules: AdBreakRules[];
    contentNetwork: string | null;
    udeOptOut: boolean;
    entityType: "AdUnit";
    calculatedPublisherReauction: boolean | null;
    overrideDynamicFloor: boolean;
    calculonUseRateOverride: number;
    upperCalculonUseRateOverride: number;
}

export interface SupplySeat {
    entityType: null;
    entityId: null;
    entityName: null;
    entityCode: null;
    lastIndexed: string;
    seatId: number;
    seatName: null;
    seatCode: null;
    isSupplySeat: null;
    enableAdServing: null;
    udeEnabled: null;
}

export interface SupplySeatPicklistParams {
    seatId: string;
    max?: number;
    keyword?: string;
    page?: number;
    channels?: boolean;
}

export interface SupplyDomainFilterListIdAndNameParams {
    seatId: string;
    type: number;
}

export interface BundleIdFilterListIdAndNameParams {
    seatId: string;
    type: number;
}

export type SupplyDomainFilterListIdAndName = [number, string];
export type BundleIdFilterListIdAndName = [number, string];

export interface SupplyTypeParams {
    seatId: string;
}

interface SupplyValidationPayloadParams {
    seatId: number;
    body: string[];
}

export interface BrandSupplyPayload {
    brand: { id: number };

    // General
    name: string;
    code: string;
    description: string;
    type: { id: number };
    isDynamic: boolean;

    // Brand Safety
    seatAdvertiserDomainFilterListDefs: { id: number; name?: string }[];
    advertiserBlockDomains: string[] | null;
    buyerSeatList: { id: number; name?: string } | null;

    // Site Details Section
    supplyNameOverride: string;
    domain: string | null;
    inventoryPartnerDomain: string | null;

    // App Details Section
    appStore: { id: number; name?: string } | null;
    bundleId: string;
    bundleIdIsOverride: boolean;
    storeUrl: string;
    paidType: { id: number } | null;

    // Exchange Safety Section is isSysAdmin || isPubAcctMgr only
    supplySupplyDomainFilterLists?: { id: number; name?: string }[];
    supplyBundleIdFilterLists?: { id: number; name?: string }[];

    // Internal Section is isTremorUser only
    resellType?: { id: number; name?: string } | null;
    enableNoAdCookieSync?: boolean;
    calculonUseRateOverride?: number | null;
    upperCalculonUseRateOverride?: number | null;
    // isTremorUser && (isPubAcctMgr || isSysAdmin) only
    publisherReauction?: boolean | null;

    // Supply Details Section
    videoQuality: { id: number; name?: string } | null;
    sourceRelationship: { id: number } | null;
    ifaType?: { id: number; name?: string } | null;
    allowVastWrappers: boolean;
    isCoppa: boolean | null;
    hasPrivacyPolicy: boolean | null;
    viewabilityVendors?: { id: number; name?: string }[];
    keywords: Array<string | number> | null;
    maskIFAForNetworks: { id: number; name?: string }[];

    // Custom Pixels
    thirdPartyPixels: { id: number; name?: string }[];

    // Labels
    labelValues: { id: number; value: string; label: Label }[];
    internalLabelValues?: { id: number; value: string; label: Label }[];

    // Waterfall Prefiltering
    prefilterLabelValues: { id: number; value: string; label: Label }[];
}

interface CreateBrandSupplyParams {
    brandId: number;
    body: BrandSupplyPayload;
}

interface UpdateBrandSupplyPayload extends BrandSupplyPayload {
    id: number;
}

export interface updateSupplyTrafficParams {
    message?: string;
    body: { id: number | string };
}

export interface runSupplyTrafficParams {
    message?: string;
    id: number | string;
}

export interface SeatNukeStatus {
    seatHasNukedSupply: boolean;
    nukedSupplyMap: { [code: string]: boolean };
    pubHasNukedSupplyMap: { [code: string]: boolean };
    brandHasNukedSupplyMap: { [code: string]: boolean };
}

export interface StructuredSeatNukesStatus {
    publisher_id: number;
    publisher_name: string;
    brand_id: number;
    brand_name: string;
    supply_id: number;
    supply_name: string;
    supply_code: string;
    ad_unit_id: number;
    ad_unit_name: string;
}

export interface UpdateBrandSupplyBrandSafetyPayload {
    id: number;
    advertiserBlockDomains: string[];
    buyerSeatList: { id: number } | null;
    seatAdvertiserDomainFilterListDefs: { id: number }[];
}

export const supplyApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getSupply: builder.query<BrandSupply, number | string>({
            query: (id: number) => `supply/${id};includeTransients=true`,
            providesTags: (_, __, id) => [{ type: SUPPLY, id }],
        }),
        createSupply: builder.mutation<BrandSupply, CreateBrandSupplyParams>({
            query: ({ body, brandId }: CreateBrandSupplyParams) => {
                return {
                    url: `/brands/${brandId}/supply`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err, { brandId }) => (err ? [] : [{ type: BRANDS_SUPPLY_TAG, id: brandId }]),
        }),
        updateSupply: builder.mutation<BrandSupply, UpdateBrandSupplyPayload>({
            query: (body: UpdateBrandSupplyPayload) => {
                return {
                    url: `/supply/${body.id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (_, err, { id, brand }) =>
                err
                    ? []
                    : [
                          { type: BRANDS_SUPPLY_TAG, id: brand.id },
                          { type: SUPPLY, id },
                          { type: SUPPLY_INHERITED_PIXELS_TAG, id },
                          { type: CHANGELOG_TAG, id: `${ChangeLogEntityType.Supply}-${id}` },
                      ],
        }),
        updateSupplyBrandSafety: builder.mutation<BrandSupply, UpdateBrandSupplyBrandSafetyPayload>({
            query: (body: UpdateBrandSupplyBrandSafetyPayload) => {
                return {
                    url: `supply/${body.id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (_, err, { id }) => (err ? [] : [{ type: SUPPLY, id }]),
        }),
        deleteSupply: builder.mutation<unknown, number>({
            query: (id: number) => ({
                url: `supply/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (err ? [] : [BRANDS_SUPPLY_TAG]),
        }),
        getSeatNukeStatus: builder.query<SeatNukeStatus, string | number>({
            query: (id: number) => `nukes/status/seats/${id}`,
            providesTags: (_, __, id) => [{ type: SEAT_NUKE_STATUS, id }],
        }),
        getStructuredNukes: builder.query<StructuredSeatNukesStatus[], string | number>({
            query: (id: number) => `nukes/structured/${id}`,
            providesTags: (_, __, id) => [{ type: STRUCTURED_SEAT_NUKES, id }],
        }),
        blockSupplyTraffic: builder.mutation<void, updateSupplyTrafficParams>({
            query: ({ body, ...rest }: updateSupplyTrafficParams) => {
                return {
                    url: `nukes/supply;${toQueryString(rest)}`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [SEAT_NUKE_STATUS, STRUCTURED_SEAT_NUKES]),
        }),
        runSupplyTraffic: builder.mutation<void, runSupplyTrafficParams>({
            query: ({ id, ...rest }) => ({
                url: `nukes/supply/${id};${toQueryString(rest)}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (err ? [] : [SEAT_NUKE_STATUS, STRUCTURED_SEAT_NUKES]),
        }),
        getSupplyAdUnits: builder.query<AdUnit[], number>({
            query: (id: number) => `supply/${id}/adUnits`,
            providesTags: (_, __, id) => [{ type: SUPPLY_AD_UNITS_TAG, id }],
        }),
        getSupplyPaidTypes: builder.query<SupplyPaidType[], void>({
            query: () => `supply/paidTypes`,
            providesTags: [SUPPLY_PAID_TYPES_TAG],
        }),
        getSupplyResellTypes: builder.query<SupplyResellType[], void>({
            query: () => `supply/resellTypes`,
            providesTags: [SUPPLY_RESELL_TYPES_TAG],
        }),
        getSupplyVideoQuality: builder.query<SupplyVideoQualityType[], void>({
            query: () => "supply/videoQuality",
            providesTags: [SUPPLY_VIDEO_QUALITY_TYPES_TAG],
        }),
        getSupplyRelationships: builder.query<SupplySourceRelationship[], void>({
            query: () => "supply/relationships",
            providesTags: [SUPPLY_RELATIONSHIPS_TYPES_TAG],
        }),
        getSupplyIfaTypes: builder.query<SupplyIfaTypes[], void>({
            query: () => "supply/ifaTypes",
            providesTags: [SUPPLY_IFA_TYPES_TAG],
        }),
        getViewabilityVendors: builder.query<ViewabilityVendor[], void>({
            query: () => "viewabilityVendors",
            providesTags: [VIEWABILITY_VENDORS_TAG],
        }),
        getSupplySeatPicklist: builder.query<Supply[], SupplySeatPicklistParams>({
            query: (params: SupplySeatPicklistParams) => {
                const { seatId, ...rest } = params;
                return `seats/${seatId}/supply/picklist;${toQueryString(rest)}`;
            },
        }),
        getSupplyDomainFilterListIdAndName: builder.query<
            SupplyDomainFilterListIdAndName[],
            SupplyDomainFilterListIdAndNameParams
        >({
            query: (params: SupplyDomainFilterListIdAndNameParams) => {
                const { seatId, ...rest } = params;
                return `seats/${seatId}/supplyDomainFilterListIdAndName;${toQueryString(rest)}`;
            },
            providesTags: [SUPPLY_DOMAIN_FILTER_LIST_ID_AND_NAME_TAG],
        }),
        getBundleIdFilterListIdAndName: builder.query<BundleIdFilterListIdAndName[], BundleIdFilterListIdAndNameParams>(
            {
                query: (params: BundleIdFilterListIdAndNameParams) => {
                    const { seatId, ...rest } = params;
                    return `seats/${seatId}/bundleIdFilterListIdAndName;${toQueryString(rest)}`;
                },
                providesTags: [SUPPLY_BUNDLE_ID_FILTER_LIST_ID_AND_NAME_TAG],
            }
        ),
        getSeatSupplies: builder.query<Supply[], SupplySeatPicklistParams>({
            query: (params: SupplySeatPicklistParams) => {
                const { seatId, ...rest } = params;
                return `seats/${seatId}/supply;${toQueryString(rest)}`;
            },
        }),
        getSeatAdsText: builder.query<string, { id: number; isSeat: boolean }>({
            query: ({ id, isSeat }) => ({
                url: isSeat ? `seats/${id}/adsText` : `publishers/${id}/adsText`,
                responseHandler: (response) => response.text(),
            }),
            providesTags: (_, __, { id, isSeat }) => [{ type: SEAT_ADS_TEXT_TAG, id, isSeat }],
        }),
        getSupplySeat: builder.query<SupplySeat | null, number>({
            query: (id: number) => `solr/platform/supply/seat/${id}`,
            providesTags: (_, __, id) => [{ type: SUPPLY_SEAT_TAG, id }],
        }),
        getSupplyTypes: builder.query<SupplyType[], SupplyTypeParams | void>({
            query: (params) => `/supply/types;${toQueryString(params)}`,
            providesTags: [SUPPLY_TYPES_TAG],
        }),
        validateSupply: builder.mutation<SupplyType[], SupplyValidationPayloadParams>({
            query: ({ seatId, body }: SupplyValidationPayloadParams) => {
                return {
                    url: `validations/access;seatId=${seatId};type=supply`,
                    method: "POST",
                    body,
                };
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetSupplyVideoQualityQuery,
    useGetViewabilityVendorsQuery,
    useGetSupplyRelationshipsQuery,
    useGetSupplyIfaTypesQuery,
    useGetSupplyPaidTypesQuery,
    useGetSupplyAdUnitsQuery,
    useLazyGetSupplyAdUnitsQuery,
    useGetSupplySeatQuery,
    useGetSupplyQuery,
    useCreateSupplyMutation,
    useUpdateSupplyMutation,
    useDeleteSupplyMutation,
    useGetSupplyTypesQuery,
    useGetSupplySeatPicklistQuery,
    useGetSeatSuppliesQuery,
    useGetSeatNukeStatusQuery,
    useGetStructuredNukesQuery,
    useBlockSupplyTrafficMutation,
    useRunSupplyTrafficMutation,
    useValidateSupplyMutation,
    useGetSupplyResellTypesQuery,
    useGetSupplyDomainFilterListIdAndNameQuery,
    useGetBundleIdFilterListIdAndNameQuery,
    useGetSeatAdsTextQuery,
    useUpdateSupplyBrandSafetyMutation,
} = supplyApi;
